@import "~bootstrap/scss/bootstrap";
@import "~aos/dist/aos.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "./Gallery.scss";
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Black.eot");
  src: local("Poppins Black"), local("Poppins-Black"),
    url("../fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Black.woff2") format("woff2"),
    url("../fonts/Poppins-Black.woff") format("woff"),
    url("../fonts/Poppins-Black.ttf") format("truetype"),
    url("../fonts/Poppins-Black.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.eot");
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype"),
    url("../fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-BlackItalic.eot");
  src: local("Poppins Black Italic"), local("Poppins-BlackItalic"),
    url("../fonts/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-BlackItalic.woff2") format("woff2"),
    url("../fonts/Poppins-BlackItalic.woff") format("woff"),
    url("../fonts/Poppins-BlackItalic.ttf") format("truetype"),
    url("../fonts/Poppins-BlackItalic.svg#Poppins-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-BoldItalic.eot");
  src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
    url("../fonts/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-BoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-BoldItalic.woff") format("woff"),
    url("../fonts/Poppins-BoldItalic.ttf") format("truetype"),
    url("../fonts/Poppins-BoldItalic.svg#Poppins-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraBold.eot");
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
    url("../fonts/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/Poppins-ExtraBold.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLight.eot");
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
    url("../fonts/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/Poppins-ExtraLight.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraBoldItalic.eot");
  src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"),
    url("../fonts/Poppins-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/Poppins-ExtraBoldItalic.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLightItalic.eot");
  src: local("Poppins ExtraLight Italic"), local("Poppins-ExtraLightItalic"),
    url("../fonts/Poppins-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLightItalic.woff") format("woff"),
    url("../fonts/Poppins-ExtraLightItalic.ttf") format("truetype"),
    url("../fonts/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.eot");
  src: local("Poppins Light"), local("Poppins-Light"),
    url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype"),
    url("../fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Italic.eot");
  src: local("Poppins Italic"), local("Poppins-Italic"),
    url("../fonts/Poppins-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Italic.woff2") format("woff2"),
    url("../fonts/Poppins-Italic.woff") format("woff"),
    url("../fonts/Poppins-Italic.ttf") format("truetype"),
    url("../fonts/Poppins-Italic.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.eot");
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype"),
    url("../fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-LightItalic.eot");
  src: local("Poppins Light Italic"), local("Poppins-LightItalic"),
    url("../fonts/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-LightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-LightItalic.woff") format("woff"),
    url("../fonts/Poppins-LightItalic.ttf") format("truetype"),
    url("../fonts/Poppins-LightItalic.svg#Poppins-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-MediumItalic.eot");
  src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
    url("../fonts/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-MediumItalic.woff2") format("woff2"),
    url("../fonts/Poppins-MediumItalic.woff") format("woff"),
    url("../fonts/Poppins-MediumItalic.ttf") format("truetype"),
    url("../fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.eot");
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBoldItalic.eot");
  src: local("Poppins SemiBold Italic"), local("Poppins-SemiBoldItalic"),
    url("../fonts/Poppins-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBoldItalic.woff") format("woff"),
    url("../fonts/Poppins-SemiBoldItalic.ttf") format("truetype"),
    url("../fonts/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("../fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ThinItalic.eot");
  src: local("Poppins Thin Italic"), local("Poppins-ThinItalic"),
    url("../fonts/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-ThinItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ThinItalic.woff") format("woff"),
    url("../fonts/Poppins-ThinItalic.ttf") format("truetype"),
    url("../fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Thin.eot");
  src: local("Poppins Thin"), local("Poppins-Thin"),
    url("../fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/Poppins-Thin.woff") format("woff"),
    url("../fonts/Poppins-Thin.ttf") format("truetype"),
    url("../fonts/Poppins-Thin.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.eot");
  src: url("../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Black.woff2") format("woff2"),
    url("../fonts/Roboto-Black.woff") format("woff"),
    url("../fonts/Roboto-Black.ttf") format("truetype"),
    url("../fonts/Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BlackItalic.eot");
  src: url("../fonts/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-BlackItalic.woff2") format("woff2"),
    url("../fonts/Roboto-BlackItalic.woff") format("woff"),
    url("../fonts/Roboto-BlackItalic.ttf") format("truetype"),
    url("../fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto-Bold.ttf") format("truetype"),
    url("../fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BoldItalic.eot");
  src: url("../fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-BoldItalic.woff2") format("woff2"),
    url("../fonts/Roboto-BoldItalic.woff") format("woff"),
    url("../fonts/Roboto-BoldItalic.ttf") format("truetype"),
    url("../fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Italic.eot");
  src: url("../fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Italic.woff2") format("woff2"),
    url("../fonts/Roboto-Italic.woff") format("woff"),
    url("../fonts/Roboto-Italic.ttf") format("truetype"),
    url("../fonts/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.eot");
  src: url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto-Light.woff") format("woff"),
    url("../fonts/Roboto-Light.ttf") format("truetype"),
    url("../fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-LightItalic.eot");
  src: url("../fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-LightItalic.woff2") format("woff2"),
    url("../fonts/Roboto-LightItalic.woff") format("woff"),
    url("../fonts/Roboto-LightItalic.ttf") format("truetype"),
    url("../fonts/Roboto-LightItalic.svg#Roboto-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto-Medium.ttf") format("truetype"),
    url("../fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-MediumItalic.eot");
  src: url("../fonts/Roboto-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Roboto-MediumItalic.woff2") format("woff2"),
    url("../fonts/Roboto-MediumItalic.woff") format("woff"),
    url("../fonts/Roboto-MediumItalic.ttf") format("truetype"),
    url("../fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype"),
    url("../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.eot");
  src: url("../fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/Roboto-Thin.woff") format("woff"),
    url("../fonts/Roboto-Thin.ttf") format("truetype"),
    url("../fonts/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-ThinItalic.eot");
  src: url("../fonts/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-ThinItalic.woff2") format("woff2"),
    url("../fonts/Roboto-ThinItalic.woff") format("woff"),
    url("../fonts/Roboto-ThinItalic.ttf") format("truetype"),
    url("../fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

$f_primary: "Poppins";
$_roboto: "Roboto";
$c_primary: #59d7ff;
$c_black: #000000;
$c_white: #ffffff;
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $f_primary !important;
}
body {
  font-family: $f_primary;
}
.header {
  position: fixed;
  left: 0;
  top: 30px;
  width: 100%;
  z-index: 100;
  background: transparent;
  transition: all 0.2s ease-out;
  &.bgdrk {
    background: #ffffffeb;
  }
  .navbar-brand {
    background-color: $c_black;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    img {
      width: 250px;
    }
  }
  .navbar-light {
    background-color: transparent !important;
    transition: all 0.2s ease-out;
    padding: 0;
  }
  .navbar-nav {
    align-items: center;
    .nav-link {
      text-transform: uppercase;
      color: $c_black;
      font-weight: normal;
      margin-right: 20px;
      font-size: 18px;
      &:hover {
        color: $c_primary;
      }
    }
    .dropdown-menu {
      background-color: transparent;
      border: 1px solid #c4c4c4;
      border-radius: 0;
      padding: 5px 10px;
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-out;
      top: 50px;
      max-width: 90px;
      min-width: inherit;
      &.show {
        top: 35px;
        opacity: 1;
        pointer-events: visible;
      }
      a {
        text-transform: uppercase;
        color: $c_black;
        font-weight: normal;
        display: block;
        margin: 0px 0;
        text-align: right;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover {
          color: $c_primary;
        }
      }
    }
  }
}
.cntctbtn {
  border: none !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  color: $c_white !important;
  background-color: $c_black !important;
  box-shadow: none !important;
  height: 45px;
  font-weight: 600 !important;
  width: 118px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    background-color: $c_primary !important;
  }
}
.lngslctprnt {
  margin: 0 20px !important;
}
.cstmlng_slsct {
  height: 45px;
  .MuiSelect-select {
    font-weight: 400 !important;
    font-family: $f_primary !important;
    font-size: 18px !important;
    color: $c_black;
  }
  fieldset {
    border: none !important;
  }
}
@media screen and (max-width: 1280px) {
  .header {
    background: #ffffffb5;
  }
}
@media screen and (max-width: 991px) {
  .header {
    .navbar {
      .navbar-brand {
        height: 55px;
      }
      .navbar-toggler {
        border: none !important;
        border-radius: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: $c_primary !important;
        padding: 4px 8px;
      }
      .navbar-collapse {
        position: fixed;
        left: -265px;
        top: 57px;
        height: calc(100% - 57px) !important;
        background: #59d7ff;
        z-index: 99;
        width: 265px;
        display: block !important;
        transition: all 0.2s ease-out;
        padding-top: 10px !important;
        &.show {
          left: 0;
        }
      }
    }
    .navbar-nav {
      align-items: flex-start;
      padding: 0 15px;
      a {
        &:hover {
          color: $c_white !important;
        }
      }
      .dropdown-menu {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
  .cntctbtn {
    width: 100% !important;
    padding: 8px !important;
    height: auto !important;
    &:hover {
      background-color: #29a8d1 !important;
    }
  }
}
@media screen and (max-width: 575px) {
  .header .navbar-brand img {
    width: 120px;
  }
  .lngslctprnt {
    margin: 0 !important;
  }
  .header .navbar .navbar-brand {
    height: 45px;
  }
  .cstmlng_slsct .MuiSelect-select {
    padding: 0 30px 0 0 !important;
  }
  .header .navbar .navbar-collapse {
    top: 45px;
    height: calc(100% - 45px) !important;
  }
}
.banner-anim {
  height: 360px;
  // overflow: visible !important;
  overflow: hidden;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.banner-thumb-bottom {
  .banner-anim-elem {
    height: 560px;
  }
  .banner-anim-thumb {
    background: #999;
  }
}

.banner-anim-elem .bg {
  width: 100%;
  min-height: 889px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.text-wrapper {
  color: #fff;
  text-align: center;
  margin-top: 100px;
  h1,
  h2 {
    color: #fff;
    font-weight: lighter;
  }
  h1 {
    font-size: 42px;
    margin-bottom: 8px;
    line-height: 42px;
    font-family: "PingFang SC,Helvetica Neue,Helvetica,Hiragino Sans GB,Microsoft YaHei,\\5FAE\8F6F\96C5\9ED1,Arial,sans-serif";
  }
  h2 {
    font-size: 12px;
    margin-bottom: 12px;
  }
  p {
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.banner-anim {
  position: relative;
  overflow: hidden;
  // min-height: 100vh;
  min-height: 889px;
}
.banner-anim-elem {
  min-height: 889px;
}
.banner-anim-arrow {
  cursor: pointer;
  z-index: 10;
}
.banner-anim-arrow-default {
  position: absolute;
  width: 20px;
  height: 45px;
  margin-top: -30px;
  top: 58%;
  background: url(../img/sldr_l_arrow.svg) no-repeat 0px 10px;
  display: none !important;
}
.service_sc .banner-anim-arrow-default {
  display: block !important;
}
.banner-anim-arrow-default.prev {
  left: 350px;
  width: 40px;
  border-right: 1.44706px solid #000000;
}
.banner-anim-arrow-default.next {
  left: 412px;
  background: url(../img/sldr_r_arrow.svg) no-repeat 0px 10px;
}
.banner-anim-thumb {
  position: absolute;
  top: 320px;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  pointer-events: none;
  z-index: 10;
  left: 350px;
}
.banner-anim-thumb-default {
  height: 40px;
  line-height: 40px;
}
.banner-anim-thumb-default span {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 0 5px;
}
.banner-anim-thumb > span {
  pointer-events: auto;
  cursor: pointer;
  display: inline-block;
  list-style: none;
  background: #94d8ee;
  transition: all 0.2s ease-out;
}
.banner-anim-thumb-default span:active,
.banner-anim-thumb-default span.active {
  background: $c_black;
  width: 40px;
}

.blured_bx {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blured_bx {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blured_bx {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.banner_md_sc {
  position: absolute;
  left: 350px;
  top: 211px;
  z-index: 999px;
  .banner_md_h1_prnt {
    min-height: 108px;
    display: flex;
    align-items: flex-end;
  }
}
.banner_md_sc h1 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 36px;
  line-height: 54px;
  color: $c_black;
  transform: none !important;
  b {
    display: block;
  }
  .breck_word {
    display: block;
  }
  span {
    font-weight: bold;
  }
}
.banner_bttm_sc {
  position: absolute;
  left: 350px;
  bottom: 70px;
  display: flex;
  align-items: center;
  z-index: 9;
}
.banner_inf_box {
  padding: 20px;
  margin-right: 25px;
  width: 314px;
  max-width: 314px;
  background: rgba(156, 156, 156, 0.1);
  backdrop-filter: blur(10px);
  .nft_shop_link {
    font-family: $f_primary;
    font-weight: 600;
    font-size: 18px;
    color: $c_black;
    margin-top: 10px;
    margin-bottom: 8px;
    display: block;
    cursor: pointer;
    span {
      font-family: $f_primary;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #676767;
      margin-left: 10px;
    }
    &:hover {
      color: #59d7ff;
    }
  }
  p {
    font-family: $f_primary;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: $c_black;
  }
}
.mainBannerSc {
  position: relative;
}
.mddl_anmtn {
  position: absolute;
  left: calc(64% - 125px);
  bottom: 62px;
  z-index: 9;
  @media screen and (max-width: 1850px) {
    left: calc(69% - 125px);
  }
  @media screen and (max-width: 1700px) {
    left: calc(73% - 125px);
  }
}
.banner_cntr_sc {
  position: relative;
  background: #59d7ff;
  height: 270px;
  width: 248px;
  max-width: 175px;
  padding: 28px 15px;
  display: block;
  text-decoration: none;
  transition: all 0.2s ease-out;
  &:hover {
    background: #3abae3;
  }
  h6 {
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: $c_black;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 600;
    font-size: 34px;
    line-height: 34px;
    text-transform: uppercase;
    color: $c_black;
  }
}
.mtmsk_icon {
  position: absolute;
  right: 15px;
  bottom: 28px;
  z-index: 9;
}
.link_ic {
  position: absolute;
  left: -32px;
  bottom: 70px;
  width: 64px;
}
@media screen and (max-width: 1600px) {
  .banner-anim {
    // min-height: 700px;
  }
  .banner-anim-thumb {
    top: 255px;
  }
  .banner_md_sc h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .banner_cntr_sc {
    height: 250px;
    width: 180px;
    max-width: 220px;
    padding: 20px 15px;
    h6 {
      font-size: 14px;
      line-height: 14px;
    }
    h4 {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .link_ic {
    position: absolute;
    left: -30px;
    bottom: 70px;
    width: 65px;
  }
  .mtmsk_icon {
    width: 50px;
    bottom: 20px;
  }
  .mddl_anmtn {
    left: calc(70% - 85px);
    bottom: 25px;
  }
  .banner_bttm_sc {
    bottom: 30px;
  }
  .banner_inf_box {
    padding: 15px;
    width: 265px;
    h6 {
      font-size: 15px;
    }
    p {
      font-size: 13px;
      line-height: 18px;
    }
  }
  .banner_md_sc {
    top: 160px;
    left: 150px;
  }
  .banner-anim-thumb {
    left: 150px;
  }
  .banner_bttm_sc {
    left: 150px;
  }
  .banner-anim-arrow-default.prev {
    left: 153px;
  }
  .banner-anim-arrow-default.next {
    left: 220px;
  }
}
@media screen and (max-width: 1366px) {
  .banner-anim-elem .bg {
    min-height: 633px;
  }
  .banner-anim-arrow-default {
    top: 64%;
  }
  .banner-anim-elem {
    min-height: 633px;
  }
  .banner-anim {
    min-height: 633px;
  }
}
@media screen and (max-width: 1230px) {
  .mddl_anmtn {
    left: calc(71% - 85px);
    bottom: 25px;
  }
  // .banner_md_sc{

  // }
  // .banner-anim {min-height: 100vh;}
}
@media screen and (max-width: 1180px) {
  .mddl_anmtn {
    left: auto;
    right: 5px;
    bottom: 25px;
  }
  .banner_cntr_sc {
    width: 150px;
  }
}
// @media screen and (max-width: 1230px) {
//   .banner_md_sc {
//     top: 15%;
//     left: 10px;
//   }
//   .banner-anim-thumb {
//     top: 24%;
//     left: 10px;
//   }
//   .mddl_anmtn {
//     left: auto;
//     right: 10px;
//   }
//   .banner-anim-arrow-default {
//     top: 33%;
//   }
//   .banner_bttm_sc, .banner-anim-arrow-default.prev {
//     left: 10px;
//   }
//   .banner-anim-arrow-default.next {
//     left: 74px;
//   }
// }
@media screen and (max-width: 991px) {
  .banner-anim-elem .bg {
    min-height: 500px;
  }
  .banner-anim-elem {
    min-height: 500px;
  }
  .mddl_anmtn {
    left: auto;
    right: 10px;
  }
  .banner_md_sc {
    // top: 85px;
    left: 25px;
    top: 94px;
  }
  .banner-anim {
    min-height: 500px;
  }
  .banner-anim-thumb {
    top: 185px;
    left: 22px;
  }
  .banner-anim-arrow-default {
    // top: 240px;
  }
  .banner_bttm_sc,
  .banner-anim-arrow-default.prev {
    left: 25px;
  }
  .banner-anim-arrow-default.next {
    left: 85px;
  }
  .banner-anim-arrow-default {
    top: 66%;
  }
  .banner_bttm_sc {
    bottom: 16px;
  }
}
@media screen and (max-width: 767px) {
  .mddl_anmtn {
    bottom: auto;
    top: 80px;
  }
  .header {
    top: 15px;
  }
}
@media screen and (max-width: 600px) {
  .banner_bttm_sc {
    flex-direction: column;
    .banner_inf_box {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  .mddl_anmtn {
    bottom: 340px;
  }
  .banner-anim {
    min-height: 670px;
  }
  .banner-anim-arrow-default {
    top: 52%;
  }
}
@media screen and (max-width: 575px) {
  .banner_md_sc {
    left: 15px;
    top: 64px;
  }
  .banner-anim-thumb {
    top: 160px;
    left: 12px;
  }
  .banner-anim-arrow-default {
    top: 41%;
  }
  .banner-anim-arrow-default.prev {
    left: 15px;
  }
  .banner-anim-arrow-default.next {
    left: 79px;
  }
  .banner-anim-elem {
    min-height: 690px;
  }
  .banner-anim-elem .bg {
    min-height: 690px;
  }
  .mddl_anmtn {
    top: 165px;
  }
}
@media screen and (max-width: 414px) {
  .banner_md_sc h1 {
    font-size: 18px;
  }
}
/**/
.footer {
  position: relative;
  background: $c_black;
  color: $c_white;
  padding: 0 !important;
  .min_height_180 {
    min-height: 180px;
  }
  .foter_ic {
    position: absolute;
    top: 88px;
    left: calc(34% - 44px);
  }
}
.ftr_bg_lgl {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
}
.container {
  max-width: 1430px !important;
}
.ftrlg_bx {
  a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    padding-bottom: 12px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    .foot_img_smlo_ic_01{
      position: absolute;
      left: -6px;
      bottom: -11px;
    }
    .foot_img_smlo_ic_02{
      position: absolute;
      right: -23px;
      bottom: -11px;
    }
  }
  
  // p {
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 28px;
  //   line-height: 42px;
  //   color: $c_white;
  //   opacity: 0.6;
  // }
  p {
    font-style: normal;
    color: $c_white;
    opacity: 0.6;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.17em;
  }
}
a {
  text-decoration: none;
}
.ftrhr {
  margin-top: 30px;
  margin-bottom: 10px;
}
.ftrlnks_v2 {
  display: flex;
  flex-direction: column;
  h5 {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $c_white;
    margin-bottom: 10px;
  }
  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    color: $c_white;
    transition: all 0.2s ease-out;
    &:hover {
      color: $c_primary;
    }
  }
}
.ftrscl_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $c_white;
    opacity: 0.6;
    margin-right: 25px;
  }
  a {
    opacity: 0.6;
    transition: all 0.2s ease-out;
    &:hover {
      opacity: 1;
    }
  }
}
.p_rltv {
  position: relative;
}
.ftr_form {
  h5 {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $c_white;
    opacity: 0.6;
    margin-bottom: 15px;
  }
  .form-control {
    background: rgba(243, 243, 243, 0.2);
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $c_white;
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    resize: none;
    height: 42px;
  }
  textarea {
    height: 100px !important;
    padding-right: 110px !important;
  }
  .sbmtfrm_btn {
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    background: #59d7ff;
    height: 100px !important;
    width: 90px;
    position: absolute;
    top: 0;
    right: 13px;
    &:hover {
      background: #77d8f7;
    }
  }
}
.lastftr {
  p {
    margin-top: 8px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
  }
}
.marg_p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #7D7D7D;
  background: #EFFEFF;
  display: flex;
}
@media screen and (max-width: 1600px) {
  .ftr_bg_lgl {
    width: 50px;
  }
}
@media screen and (max-width: 1440px) {
  .ftr_bg_lgl {
    opacity: 0.3;
  }
}
@media screen and (max-width: 1199px) {
  .footer .foter_ic {
    left: calc(42% - 49px);
  }
}
@media screen and (max-width: 991px) {
  .ftrscl_bx {
    display: block;
    p {
      margin-bottom: 10px;
    }
    a {
      margin-right: 15px;
    }
  }
  .footer .foter_ic {
    left: calc(86% - 40px);
    max-width: 80px;
  }
}
@media screen and (max-width: 767px) {
  .ftrlg_bx {
    margin-bottom: 20px;
    p {
      font-size: 18px;
    }
  }
  .ftrlnks_v2 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px) {
  .ftrlg_bx {
    p {
      font-size: 15px;
      line-height: 30px;
    }
  }
}
.our_work_sc {
  background: url(../img/slider_bg.jpg) no-repeat right 0;
  background-size: cover;
  min-height: 1140px;
  padding-top: 120px;
  padding-bottom: 150px;
}
.our_work_inn {
  background: rgba(213, 213, 213, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 60px 60px 0px 0px;
  min-height: 765px;
  padding: 0 90px 90px 90px;
  max-width: 1600px;
  margin: 0 auto;
}
.sldr_box {
  border: 0.81106px solid #9fa4b4;
  overflow: hidden;
}
.sldr_box_top {
  background: #ececec;
  box-shadow: 0px 24.3318px 48.6636px rgba(0, 0, 0, 0.15);
  padding: 6px;
}
.sldr_box_bottom {
  padding: 12px 25px 15px 25px;
  display: flex;
  flex-direction: column;
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $c_black;
    margin-bottom: 15px;
    text-align: center;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $c_black;
    margin-bottom: 14px;
    min-height: 50px;
    text-align: center;
    @media screen and (max-width: 1080px) {
      br {
        display: none;
      }
    }
  }
}
.prtnr_prjtct_main_bx {
  .sldr_box_bottom {
    min-height: 210px;
    justify-content: space-between;
  }
}

.sldr_btn {
  width: 122.78px !important;
  height: 33px !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  line-height: 22px !important;
  text-align: center !important;
  color: $c_black !important;
  margin: 0 auto !important;
  text-transform: uppercase !important;
  border: 0.81106px solid #000000 !important;
  filter: drop-shadow(
    0px 3.24424px 24.3318px rgba(126, 0, 224, 0.8)
  ) !important;
  transition: all 0.2s ease-out !important;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: $c_primary !important;
    border: 0.81106px solid $c_primary !important;
  }
}
.slid_prnt_bx{
  .sldr_btn {
    filter: none !important;
  }  
}
.slider_one {
  margin-bottom: 30px;
  .sldr_box {
    margin-bottom: 30px;
  }
  .row {
    margin-top: -40px;
  }
}
.slider_one_title {
  padding-bottom: 40px;
  h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    span {
      font-weight: 200;
      display: block;
    }
  }
}
.sldr_pdng {
  padding: 20px 10px;
}
.slick_action {
  margin: 15px auto 0 auto;
  text-align: center;
  button {
    background: transparent !important;
    color: $c_black !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    
    &:first-child {
      border-right: 1.44706px solid #000000 !important;
      padding-right: 7px !important;
    }
    &:hover {
      color: $c_primary !important;
    }
  }
}
@media screen and (max-width: 1600px) {
  .our_work_inn {
    padding: 0 20px 30px 20px;
  }
}
@media screen and (max-width: 1199px) {
  .sldr_box_bottom {
    padding: 12px 10px 15px 10px;
  }
}
@media screen and (min-width: 992px) {
  .hm_mbl_sldr {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .our_work_sc {
    min-height: inherit !important;
  }
  .slider_one,
  .hm_dbl_sldr {
    display: none !important;
  }
  .sldrordr {
    order: -1;
    margin-top: 25px;
    display: none;
  }
  .slider_one .row {
    margin-top: 0;
  }
  .slider_one_title {
    margin-top: 30px !important;
  }
  .our_work_inn {
    min-height: 630px !important;
  }
}
@media screen and (max-width: 575px) {
  .slider_one_title h3 {
    font-size: 24px;
    line-height: 36px;
  }
}
.nft_str_box {
  position: relative;
  padding-bottom: 45px;
  // margin-top: 40px;
  padding-top: 100px;
}
.def_bl_ttl {
  background: #000000;
  color: $c_white;
  display: inline-block;
  position: relative;
  // padding: 6px 13px 6px 0;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 22px !important;
  text-transform: uppercase;
  margin-bottom: 60px !important;
  max-width: 320px;
  width: 100%;
  padding: 6px 0 10px 20px;

  span {
    // display: block;
    font-weight: 200 !important;
  }
  .cmng_sn_txt {
    font-family: $_roboto !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 14px;
    color: #fa15ff;
    text-transform: none;
    margin-left: 0px !important;
    position: relative;
    top: -2px;
  }
  .crypt_swg_txt {
    color: #59d7ff;
    line-height: 24px;
  }
}
.ttl_back {
  position: absolute;
  left: 0;
  top: 0;
  height: 96px;
  width: 21%;
  background-color: $c_black;
}
.cnnctwllt_btn {
  position: relative;
  background: #59d7ff;
  height: 85px;
  padding: 0 54px 0 35px;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  // text-decoration-line: underline;
  color: $c_black !important;
  &:hover,
  &:active,
  &:focus {
    background: #6adafd !important;
  }
  span {
    background-color: $c_black;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -27px;
    top: 15px;
    color: $c_white;
  }
  @media screen and (max-width: 1280px) {
    height: 55px;
    padding: 0 25px 0 35px;
    font-size: 14px;
    span {
      height: 36px;
      width: 36px;
      left: -13px;
      top: 10px;
    }
  }
}
@media screen and (max-width: 767px) {
  .ttl_back {
    display: none;
  }
  .def_bl_ttl {
    font-size: 24px !important;
    line-height: 32px !important;
    padding: 6px 13px 6px 13px;
    margin-bottom: 30px !important;
  }
  .cnnctwllt_btn {
    margin-bottom: 30px;
    height: 78px;
    span {
      top: 12px;
    }
  }
  .nft_str_box {
    padding-top: 30px;
  }
}
.smthngabt {
  padding: 60px 0;
  h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    color: $c_black;
    margin-bottom: 40px;
  }
}
.smng_lft {
  border: 0.81106px solid #9fa4b4;
  border-radius: 24.3318px;
  padding: 50px 40px 40px 40px;
  p {
    font-weight: 300;
    font-size: 22px;
    line-height: 32px;
    color: $c_black;
    margin-bottom: 20px;
  }
}
.smng_rgt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  h4 {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: $c_black;
    margin-bottom: 15px;
  }
  p {
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #9fa4b4;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1199px) {
  .smthngabt h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .smng_lft p {
    font-size: 16px;
    line-height: 24px;
  }
  .smng_lft {
    padding: 25px 15px 5px 15px;
  }
  .smng_rgt {
    padding: 0;
    h4 {
      font-size: 18px;
      line-height: 24px;
    }
    p {
      font-size: 13px;
    }
  }
}
@media screen and (max-width: 767px) {
  .smng_rgt {
    margin-top: 30px;
  }
}
.mrchstr_main {
  background: url(../img/mrchstr_bg.jpg) no-repeat left 110px;
  margin-top: 60px;
  padding-bottom: 160px;
  .def_lnks {
    margin-bottom: 20px;
  }
}
.mrchstr_ttl {
  // margin-bottom: 20px !important;
  h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
    margin-bottom: 10px;
    span {
      // display: block;
      font-weight: 200;
    }
  }
}
.def_lnks {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-decoration-line: underline;
  color: $c_black;
  transition: all 0.2s ease-out;
  &:hover {
    color: $c_primary;
  }
}
.mrch_items {
  background: rgba(156, 156, 156, 0.1);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  // border: 1px solid #9fa4b4;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $c_black;
    backdrop-filter: blur(20px);
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    // margin-top: 24px;
    // margin-bottom: 10px;
    transition: all 0.2s ease-out;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $c_black;
    // margin-bottom: 14px;
    height: 47px;
    background: #EEEEEE;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  // &:hover {
  //   h4 {
  //     color: #59d7ff;
  //   }
  // }
}
.mrch_items_action {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-direction: column;
  h6 {
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $c_black;
  }
  button {
    margin-left: auto;
    background: #DDDDDD;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border: none !important;
    height: 33px;
    width: 172px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    margin: 0 auto;
    border-radius: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    position: relative;
    top: 15px;
    img {
      margin-left: -2px;
    }
    // &:hover,
    // &:focus {
    //   background: #4cccf5 !important;
    // }
  }
}
@media screen and (max-width: 767px) {
  .mrchstr_ttl h3 {
    font-size: 24px;
    line-height: 36px;
  }
  .def_lnks {
    font-size: 14px;
    line-height: 22px;
  }
  .mrchstr_main .def_lnks {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 575px) {
  .mrchstr_main .def_lnks {
    margin-top: 30px;
  }
}
.service_sc {
  position: relative;
  background: url(../img/srvc_bg.jpg) no-repeat center bottom;
  background-size: contain;
  z-index: 999;
}
.srvcttl {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 45px;
  .srv_ttl_img {
    background: $c_black;
    height: 60px;
    // padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    // padding: 6px 0 10px 20px;
    max-width: 320px;
    width: 100%;
    img {
      max-width: 228px;
    }
  }
  h3 {
    font-weight: 300;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    color: $c_black;
    span {
      font-weight: 700;
      display: block;
    }
  }
}
.srv_ttl_blkbx {
  position: absolute;
  left: 0;
  top: 0;
  background: $c_black;
  height: 96px;
  width: 21%;
}
.service_sc {
  .banner-anim {
    min-height: 0 !important;
  }
}
.service_sc {
  .banner_md_sc {
    right: 0;
    top: 0;
  }
  .banner-anim-thumb {
    top: 300px;
    left: auto;
    right: 0px;
    text-align: right;
  }
  .banner-anim-arrow-default {
    top: 335px;
  }
  .banner-anim-arrow-default.prev {
    right: 387px;
    left: auto;
  }
  .banner-anim-arrow-default.next {
    right: 347px;
    left: auto;
  }
}

@media screen and (max-width: 1600px) {
  .service_sc .banner-anim-arrow-default {
    // top: 290px;
  }
  .service_sc .banner-anim-thumb {
    // top: 260px;
  }
}
@media screen and (max-width: 1380px) {
  .service_sc .banner-anim-arrow-default.prev {
    right: 420px;
  }
  .service_sc .banner-anim-arrow-default.next {
    right: 375px;
  }
}
@media screen and (max-width: 992px) {
  .service_sc {
    .banner-anim {
      min-height: 350px;
    }
  }
  .service_sc .banner-anim-arrow-default {
    top: 67%;
  }
  .service_sc .banner-anim-arrow-default.prev {
    right: 516px !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    right: 478px !important;
  }
  .service_sc .banner-anim-thumb {
    top: 213px;
    left: 239px;
    text-align: center;
  }
  .service_sc .cntnt_crtn_frm {
    margin-top: 0;
    padding: 0px 15px 60px 15px;
  }
}
@media screen and (max-width: 930px) {
  .service_sc .banner-anim-arrow-default.prev {
    right: 550px !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    right: 505px !important;
  }
}
@media screen and (max-width: 830px) {
  .service_sc .banner-anim-arrow-default.prev {
    left: 200px !important;
    right: auto !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    left: 265px !important;
    right: auto;
  }
  .service_sc .banner-anim-thumb {
    left: 165px;
  }
}
@media screen and (max-width: 780px) {
  .service_sc .banner-anim-arrow-default.next {
    left: 240px !important;
  }
  .service_sc .banner-anim-arrow-default.prev {
    left: 170px !important;
  }
}
@media screen and (max-width: 700px) {
  .service_sc .banner-anim-arrow-default.next {
    left: 210px !important;
  }
  .service_sc .banner-anim-arrow-default.prev {
    left: 135px !important;
  }
}
@media screen and (max-width: 650px) {
  .service_sc .banner-anim-arrow-default.prev {
    left: 100px !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    left: 170px !important;
  }
  .service_sc .banner-anim-thumb {
    left: 155px;
  }
}
@media screen and (max-width: 575px) {
  .srv_ttl_blkbx {
    display: none;
  }
  .srvcttl {
    .srv_ttl_img {
      height: 45px;
      padding: 8px;
      img {
        height: 10px;
      }
    }
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .service_sc .banner-anim-arrow-default.prev {
    left: 60px !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    left: 130px !important;
  }
}
@media screen and (max-width: 500px) {
  .service_sc .banner-anim-arrow-default.prev {
    left: 30px !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    left: 100px !important;
  }
  .service_sc .banner-anim-thumb {
    left: -20px;
    text-align: right;
  }
}
@media screen and (max-width: 400px) {
  .vmo_lnk {
    margin-bottom: 0px;
    margin-top: 40px !important;
  }
  .service_sc .banner-anim-thumb {
    left: 0px;
    top: 155px;
  }
  .service_sc .banner-anim-arrow-default {
    top: 50%;
  }
  .service_sc .banner-anim-arrow-default.prev {
    left: 12px !important;
  }
  .service_sc .banner-anim-arrow-default.next {
    left: 78px !important;
  }
}
.cstm_row {
  .col-md-3 {
    width: 20%;
    padding: 0;
  }
  .col-md-4 {
    width: 40%;
    padding: 0;
  }
  .col-md-7 {
    width: 60%;
    padding: 0;
  }
}
.srv_pd_bx {
  padding: 0 15px;
}

.srv_pd_bx div {
  box-sizing: border-box;
}

.s_in_box_col {
  background-color: white;
}
.in_s_box {
  border: 1px solid #9fa4b4;
  padding: 30px 0px;
  margin-left: -1px;
  margin-top: -1px;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.clicked {
    padding-bottom: 17px;
  }
  .img-fluid {
    display: flex;
    margin: 0 auto;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  h4 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    width: 100%;
    background: #c4c4c4;
    transition: all 0.2s ease-out;
  }
  p {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #9fa4b4;
    padding: 15px 20px 30px 20px;
    &.clicked {
      padding-bottom: 16px;
    }
  }
  .wbst_bx {
    background-color: #f0f0f0;
  }
  .nft_bx {
    color: black;
  }
  // .smrtcntr_bx{
  //   background: #FFBAFD;
  // }
  // .dsgn_bx{
  //   background: #FFC7B1;
  // }
  // .dvspprt_bx{
  //   background: #FFF5C2;
  // }
}
.in_s_box_ttl {
  margin-bottom: 20px;
  min-height: 80px;
  h5 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    color: $c_black;
    margin-bottom: 12px;
  }
  p {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #9fa4b4;
  }
}
.vmo_lnk {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 102px;
  z-index: 999;
  .visible {
    display: block;
  }
  .invisible {
    display: none;
  }
  .play-btn {
    position: absolute;
    top: -381px;
    right: 0px;
    left: 0px;
    z-index: 999;
  }
  div {
    margin: 0px !important;
    margin-left: auto !important;
    @media (max-width: 991px) {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  img {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0), 0 0 40px rgba(0, 0, 0, 0) inset;
    transition: all 0.2s ease-out;
    max-width: 560px;
    width: 100%;
    margin: 0px !important;
    margin-left: auto !important;
    @media (max-width: 991px) {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  &:hover {
    img {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3),
        0 0 40px rgba(0, 0, 0, 0.1) inset;
    }
  }
}
.cntnt_crtn_frm {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(18.6783px);
  border-radius: 56.035px 56.035px 0px 0px;
  margin-top: 102px;
  padding: 18px 15px 10px 15px;
}
.crtn_frm_ttl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 470px;
  margin: 0 auto 25px auto;
  h6 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: $c_black;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 12px;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: $c_black;
  }
}
.crtn_frm_form {
  border: none;
  border-radius: 0;
  padding: 26px 20px 23px 20px;
  .bitgrafix_text_img_prnt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-control {
    background: #FFFFFF !important;
    border: 0.933917px solid #9FA4B4;
    border-radius: 0px;
    box-shadow: none !important;
    font-weight: 300;
    font-size: 14px;
    line-height: 32px;
    color: #000;
    height: 45px;
    resize: none;
    overflow: hidden;
  }
  .cntct_btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    background: #59D7FF;
    box-shadow: 0px 4px 30px rgba(89, 215, 255, 0) !important;
    // box-shadow: 0px 4px 30px rgba(89, 215, 255, 0.8) !important;
    border-radius: 0px;
    border: none !important;
    padding: 10px 50px;
    margin-top: 25px;
    color: #000 !important;
    &:hover {
      background: #2192b6;
    }
  }
}
.crtn_frm_form_ttl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 460px;
  margin: 0 auto 15px auto;
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: $c_black;
    margin-top: 15px;
  }
}
.crtn_frm_form_ttl_as {
  margin-bottom: 26px;
}
@media screen and (max-width: 1250px) {
  .in_s_box {
    padding: 15px 0;
    h5 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 5px;
    }
    min-height: 280px;
  }
  .in_s_box_ttl {
    min-height: 62px;
  }
  .cstm_row .col-md-3 {
    width: 33.33%;
  }
}
@media screen and (max-width: 991px) {
  // .cstm_row .col-md-3 {width: 33.33%;}
  .form_order {
    order: 8;
  }
  .cstm_row .col-md-4,
  .cstm_row .col-md-7 {
    width: 100%;
  }
  .vmo_lnk {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 870px) {
  .cstm_row .col-md-3 {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .cstm_row .col-md-3 {
    width: 100%;
  }
  .cntnt_crtn_frm {
    padding: 30px 15px;
  }
  .crtn_frm_ttl {
    h6 {
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-size: 15px;
      line-height: 22px;
    }
  }
  .crtn_frm_form_ttl p {
    font-size: 15px;
    line-height: 22px;
  }
}
/**/
.cmn_tp_ttl {
  min-height: 430px;
  display: flex;
  align-items: flex-end;
  background-size: cover !important;
  &.pi_c_bg {
    background: url(../img/pi_bg.jpg) no-repeat center 0;
  }
  &.defi_c_bg {
    background: url(../img/defi_bg.jpg) no-repeat center 0;
  }
  h3 {
    font-weight: bold;
    font-size: 90px;
    line-height: 63px;
    text-transform: uppercase;
    color: $c_black;
  }
  @media screen and (max-width: 1440px) {
    min-height: 330px;
    h3 {
      font-size: 60px;
      line-height: 42px;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: 330px;
    h3 {
      font-size: 40px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 575px) {
    min-height: 240px;
    h3 {
      font-size: 30px;
      line-height: 20px;
    }
  }
  @media screen and (max-width: 360px) {
    min-height: 200px;
  }
}
.pridnt {
  display: flex;
  align-items: center;
  h5 {
    background: #59d7ff;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    text-transform: uppercase;
    color: $c_black;
    padding: 10px 40px;
  }
}
.pr_logo_bx {
  padding: 20px;
  background: $c_black;
  border-radius: 12.1943px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pi_logo {
  filter: drop-shadow(0px 4px 40px rgba(255, 255, 255, 0.4));
  border-radius: 50%;
}
@media screen and (max-width: 991px) {
  .pr_logo_bx {
    padding: 10px;
  }
  .pi_logo {
    width: 60px;
  }
  .pridnt h5 {
    font-size: 20px;
    line-height: 30px;
    padding: 10px 20px;
  }
}
@media screen and (max-width: 500px) {
  .pridnt h5 {
    font-size: 16px;
    line-height: 30px;
    padding: 10px 14px;
  }
}
.pro_p {
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: $c_black;
  a {
    font-weight: 700;
    color: #0085ff;
  }
  span {
    color: #0085ff;
  }
  @media screen and (max-width: 1199px) {
    font-size: 15px !important;
    line-height: 22px !important;
  }
}
.vd_box_v1 {
  display: flex;
  align-items: center;
  video {
    width: 315px;
    margin-right: 35px;
  }
}
.def_h4 {
  font-weight: 600 !important;
  font-size: 34px !important;
  line-height: 51px !important;
  text-transform: uppercase;
  color: $c_black;
  margin-bottom: 15px !important;
  @media screen and (max-width: 1199px) {
    font-size: 22px !important;
    line-height: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  .vd_box_v1 {
    flex-direction: column;
    video {
      width: 280px;
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
}
.def_h2 {
  position: relative;
  background: #e8e8e8;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 45px !important;
  text-transform: uppercase;
  color: $c_black;
  padding: 8px 10px 8px 30px;
  &::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 11px;
    height: 40px;
    width: 28px;
    background: $c_black;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px !important;
    line-height: 36px !important;
    padding: 8px 10px 8px 20px;
    &::before {
      left: -7px;
      top: 11px;
      height: 30px;
      width: 20px;
    }
  }
}
.cstm_md9 {
  width: 69.5% !important;
}
.cstm_md3 {
  width: 29.5% !important;
}
.cstm_md6 {
  width: 40% !important;
}
@media screen and (max-width: 767px) {
  .cstm_md9 {
    padding: 5px !important;
  }
  .cstm_md3 {
    padding: 5px !important;
  }
  .cstm_md6 {
    width: 40% !important;
    padding: 5px !important;
  }
}
.productslider {
  position: relative;
  height: 540px;
  max-width: 1125px;
  margin: 0 auto 50px auto;
  video {
    height: 540px;
  }
  @media screen and (max-width: 767px) {
    height: 340px;
    padding: 0 15px;
    video {
      height: 340px;
    }
  }
}
.css-1qzevvg {
  position: absolute !important;
  left: 0;
  width: 100% !important;
  top: calc(50% - 25px);
  img {
    position: relative;
    width: 10px;
    height: 20px !important;
    cursor: pointer;
    &:first-child {
      left: -40px;
    }
    &:last-child {
      right: -40px;
    }
    @media screen and (max-width: 1230px) {
      margin-right: 0 !important;
      margin-left: 0 !important;
      &:first-child {
        left: 0px;
      }
      &:last-child {
        right: 0px;
      }
    }
  }
}
.gallary_box {
  padding-bottom: 40px;
  .col-md-4 {
    width: 14.28%;
    margin-bottom: 20px;
    video {
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      width: 20%;
    }
    @media screen and (max-width: 767px) {
      width: 25%;
    }
    @media screen and (max-width: 575px) {
      width: 33.33%;
    }
    @media screen and (max-width: 380px) {
      width: 50%;
    }
  }
}
.vd_bx_h {
  position: relative;
  cursor: pointer;
  opacity: 0.1;
  transition: all 0.2s ease-out;
  .view_btn {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 15px);
    width: 40px;
    pointer-events: none;
    transition: all 0.2s ease-out;
    opacity: 0;
  }
  &:hover {
    opacity: 100%;
    .view_btn {
      opacity: 1;
    }
  }
}
#bp_caption {
  button.bp-x {
    display: none !important;
  }
}
.prjcts_main {
  background: url(../img/promdl_bg.png) no-repeat right 34%;
}
.defi_pbx_01 {
  background: url(../img/dd_bg_01.png) no-repeat center 0;
  background-size: cover;
  min-height: 533px;
  max-width: 93%;
  padding: 0px 45px 0px 0px;
  border-radius: 0px 50px 50px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 55px;
  overflow: hidden;
  video {
    display: block;
  }
  .def_h4 {
    color: $c_white !important;
    margin-bottom: 2px !important;
  }
  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffd9c4;
    margin-bottom: 35px;
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #fcf2bd;
  }
}
@media screen and (max-width: 991px) {
  .defi_pbx_01 {
    max-width: 100%;
    padding: 0px 25px 20px 25px;
    h6 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
@media screen and (max-width: 575px) {
  .defi_pbx_01 {
    padding: 0px 5px 20px 5px;
  }
}
.defi_md06 {
  width: 44% !important;
}
.defi_ttl {
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 44px !important;
  letter-spacing: 0.135em !important;
  text-transform: uppercase;
  margin-bottom: 30px !important;
  span {
    display: block;
    font-weight: 200 !important;
    font-size: 30px !important;
    letter-spacing: 0.015em !important;
  }
}
.as-mbmns-5 {
  margin-bottom: 30px;
}
.defi_lg_txt {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  h4 {
    white-space: nowrap;
    margin-right: 20px;
    font-weight: 600;
    font-size: 34px;
    line-height: 51px;
    text-transform: uppercase;
    color: $c_black;
  }
}
.defi_pbx_04 {
  margin-bottom: 90px;
  .def_h4 {
    margin-bottom: 0px !important;
  }
}
.dngn_shdimg {
  filter: drop-shadow(0px 0px 34px rgba(50, 255, 169, 0.67));
}
.defi_lnk {
  display: block;
  margin-top: 50px;
}
.def_md_9 {
  width: 73.5% !important;
  position: relative;
}
.def_md_3 {
  width: 25.7% !important;
  position: relative;
}
.bnr.is-animetion {
  margin-top: 0.2em;
}

.bnr.is-animetion span {
  display: inline-block;
  animation: wave-text 1.5s ease-in-out infinite;
}

.bnr.is-animetion {
  span:nth-of-type(1) {
    animation-delay: 0s;
  }
  span:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  span:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  span:nth-of-type(4) {
    animation-delay: 0.3s;
  }
  span:nth-of-type(5) {
    animation-delay: 0.4s;
  }
  span:nth-of-type(6) {
    animation-delay: 0.5s;
  }
  span:nth-of-type(7) {
    animation-delay: 0.6s;
  }
  span:nth-of-type(8) {
    animation-delay: 0.7s;
  }
  span:nth-of-type(9) {
    animation-delay: 0.8s;
  }
  span:nth-of-type(10) {
    animation-delay: 0.9s;
  }
  span:nth-of-type(11) {
    animation-delay: 1s;
  }
  span:nth-of-type(12) {
    animation-delay: 1.1s;
  }
  span:nth-of-type(13) {
    animation-delay: 1.2s;
  }
  span:nth-of-type(14) {
    animation-delay: 1.3s;
  }
  span:nth-of-type(15) {
    animation-delay: 1.4s;
  }
  span:nth-of-type(16) {
    animation-delay: 1.5s;
  }
  span:nth-of-type(17) {
    animation-delay: 1.6s;
  }
  span:nth-of-type(18) {
    animation-delay: 1.7s;
  }
  span:nth-of-type(19) {
    animation-delay: 1.8s;
  }
  span:nth-of-type(20) {
    animation-delay: 1.9s;
  }
  span:nth-of-type(21) {
    animation-delay: 2s;
  }
  span:nth-of-type(22) {
    animation-delay: 2.1s;
  }
  span:nth-of-type(23) {
    animation-delay: 2.2s;
  }
}

@keyframes wave-text {
  00% {
    transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.2em);
  }
  100% {
    transform: translateY(0em);
  }
}
.tmplt_img01 {
  margin-right: 30px;
  img {
    max-width: 400px;
  }
}
@media screen and (max-width: 767px) {
  .tmplt_img01 {
    margin-right: 0px;
    margin-bottom: 30px;
    img {
      max-width: 100%;
    }
  }
}
.tmplt_mdlcntr {
  text-align: center;
  img {
    margin: 20px 20px;
    @media screen and (max-width: 767px) {
      margin: 20px 10px;
    }
  }
}
@media screen and (min-width: 768px) {
  .tmplt_b_lut_08 {
    width: 63.266667% !important;
  }
  .tmplt_b_lut04 {
    width: 36.733333% !important;
  }
  .gallary_box_v2 {
    .col-6 {
      width: 20% !important;
    }
  }
}
.prjct_lstng_main_bx {
  padding-top: 80px;
  .hdng_bx {
    padding-bottom: 30px;
    padding-top: 90px;
    h3 {
      font-family: $f_primary;
      font-weight: 200;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      text-transform: uppercase;
      color: $c_black;
      display: flex;
      flex-direction: column;
      span {
        font-weight: 700;
      }
    }
    p {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $c_black;
    }
  }
  .cards_cntnt_bx {
    background: #ffffff;
    padding: 50px 50px;
    .sldr_box {
      margin-bottom: 25px;
    }
    .sldr_box_top {
      padding: 12px 10px;
      position: relative;
      background: #fff;
    }
    .sldr_box_bottom {
      padding: 22px 30px;
      background-color: #ffffff;
      box-shadow: 0px 42.8156px 85.6312px rgba(0, 0, 0, 0.15);

      h4 {
        font-size: 22px;
        line-height: 28px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        min-height: 74px;
        max-width: 362px;
      }
    }
  }
}
.cntnt_crtn_bx {
  background: url(../img/sevc_bg_002.jpg) no-repeat center bottom;
  background-size: cover;
  min-height: 994px;
  margin-top: -200px;
  display: flex;
  align-items: flex-end;
  .crtn_frm_ttl p {
    font-size: 16px;
    line-height: 26px;
    max-width: 380px;
  }
  .crtn_frm_form_ttl p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 26px;
    max-width: 380px;
  }
  .crtn_frm_form .cntct_btn {
    color: #ffffff;
  }
}
.clck_hide_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: #000000;
    border: 1px solid #c4c4c4;
    font-family: $f_primary;
    font-weight: 400;
    font-size: 16px;
    color: $c_white;
    margin: 0 auto;
    text-transform: none !important;
    border-radius: 0 !important;
    height: 27px;
    transition: all 0.2s ease-out;
    &:hover {
      background: #75ffee;
      color: #000000;
    }
  }
}
.blck_bg_bx {
  background: #000000;
  margin: 25px 0 0 57px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  .bg_clr_bx {
    height: 20px;
    width: 125px;
    background: #59d7ff;
  }
  button {
    font-family: $f_primary !important;
    font-weight: 400;
    font-size: 18px;
    color: $c_white;
    text-transform: none;
    transition: all 0.2s ease-out;
    img {
      margin-left: 7px;
    }
    &:hover {
      color: #75ffee;
    }
  }
}
.prjct_lstng_main_bx.sldr_bttm_page {
  padding-top: 0;
  // box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.43);
  .cards_cntnt_bx {
    background: transparent;
    padding: 62px 0px 50px 250px;
  }
}
.vw_or_wrk_main_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  .lft_cntnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000000;
    height: 60px;
    max-width: 320px;
    width: 100%;
    padding: 6px 0 6px 30px;
    @media screen and (max-width: 1280px) {
      height: auto;
    }
    h3 {
      font-family: $f_primary;
      font-weight: 300;
      font-size: 22px;
      line-height: 22px;
      text-transform: uppercase;
      color: $c_white;
      span {
        display: block;
        font-weight: 700;
      }
    }
    h6 {
      font-family: $_roboto !important;
      padding-right: 9px;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      text-align: right;
      color: #59d7ff;
    }
  }
  .rght_cntnt {
    display: flex;
    align-items: center;
    p {
      font-family: $f_primary;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-decoration-line: underline;
      color: $c_black;
    }
    .ble_bx {
      height: 58px;
      width: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #59d7ff;
      margin-left: 5px;
      transition: all 0.2s ease-out;
      border-radius: 0;
      &:hover,
      &:focus {
        background: #6adafd;
        box-shadow: none;
      }
    }
  }
}
.hmpge_btn {
  position: relative;
  top: -13px !important;
}
.sldr_bttm_page {
  .HorizontalScroll .HorizontalScrollTrack {
    margin-top: 24px;
  }
  .HorizontalScroll .HorizontalScrollTrack:after {
    background: #f4f4f4;
    padding: 5px;
    border-radius: 0;
    z-index: 0;
    height: 30px;
  }
  .HorizontalScroll .HorizontalScrollTrack .HorizontalScrollTrackInner:after {
    background: #59d7ff;
    height: 17px;
    width: 125px;
    z-index: 1;
    border-radius: 0;
  }
  .HorizontalScroll .HorizontalScrollTrack .HorizontalScrollTrackInner:after {
    top: 18px;
    left: 10px;
  }
  .HorizontalScroll .HorizontalScrollTrack {
    display: block !important;
  }
}
@media screen and (max-width: 1700px) {
  .prjct_lstng_main_bx.sldr_bttm_page .cards_cntnt_bx {
    padding: 62px 0px 50px 150px;
  }
}
@media screen and (max-width: 1600px) {
  .prjct_lstng_main_bx.sldr_bttm_page .cards_cntnt_bx {
    padding: 62px 0px 50px 100px;
  }
}
@media screen and (max-width: 1500px) {
  .prjct_lstng_main_bx.sldr_bttm_page .cards_cntnt_bx {
    padding: 62px 0px 50px 50px;
  }
}
@media screen and (max-width: 1430px) {
  .vw_or_wrk_main_bx {
    margin-top: 60px;
  }
  .prjct_lstng_main_bx.sldr_bttm_page .cards_cntnt_bx {
    padding: 62px 0px 50px 12px;
  }
}
@media screen and (max-width: 1300px) {
  .prjct_lstng_main_bx .cards_cntnt_bx {
    padding: 50px 10px;
    .sldr_box_bottom {
      padding: 22px 10px;
    }
  }
}
@media screen and (max-width: 991px) {
  .prjct_lstng_main_bx {
    padding-top: 60px;
    .hdng_bx {
      padding-top: 70px;
    }
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom h4 {
    font-size: 16px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom p {
    font-size: 14px;
    line-height: 18px;
  }
  .crtn_frm_ttl h6,
  .crtn_frm_ttl .h6 {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .cntnt_crtn_bx .crtn_frm_ttl p {
    font-size: 15px;
    line-height: 22px;
  }
  .cntnt_crtn_bx .crtn_frm_form_ttl p {
    margin-top: 12px;
    font-size: 15px;
    line-height: 20px;
  }
  .cntnt_crtn_bx {
    min-height: 0;
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .cntnt_crtn_bx {
    margin-top: 0;
  }
  .prjct_lstng_main_bx .hdng_bx h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .vw_or_wrk_main_bx {
    flex-direction: column;
    .lft_cntnt {
      margin-right: auto;
    }
    .rght_cntnt {
      margin-left: auto;
      margin-top: 20px;
    }
  }
  .vw_or_wrk_main_bx {
    margin-top: 40px;
  }
  .prjct_lstng_main_bx.sldr_bttm_page .cards_cntnt_bx {
    padding: 40px 0px 50px 12px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx {
    padding: 50px 10px 20px 10px;
  }
  // .vw_or_wrk_main_bx .lft_cntnt{
  //   height: 70px;
  //   h3{
  //     font-size: 24px;
  //     line-height: 28px;
  //   }
  // }
  .cntnt_crtn_frm {
    margin-top: 20px;
  }
}
@media screen and (max-width: 500px) {
  .prjct_lstng_main_bx {
    .hdng_bx {
      padding-top: 15px;
      padding-bottom: 20px;
      h3 {
        font-size: 22px;
        line-height: 26px;
      }
      p {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  .prjct_lstng_main_bx .cards_cntnt_bx {
    padding: 25px 10px;
  }
  .clck_hide_btn button {
    font-size: 12px;
    height: 20px;
  }
  .vw_or_wrk_main_bx .lft_cntnt {
    max-width: 260px;
    padding: 6px 0 10px 20px;
    h3 {
      font-size: 17px;
      line-height: 17px;
    }
    h6 {
      font-size: 12px;
      line-height: 11px;
    }
  }
}
.HorizontalScroll .HorizontalScrollOuter {
  max-width: 100% !important;
}
.detail_main_bx {
  .detail_slider_bx {
    .three_dtl_bx {
      display: flex;
      align-items: center;
      justify-content: center;
      .lft_bx {
        .lft_tp_bx {
          display: flex;
          align-items: center;
          .links_bx {
            background: #ffffff;
            border: 1px solid #9fa4b4;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            // width: 100%;
            // max-width: 433px;
            width: 276px;
            min-height: 276px;
            button,
            .same_btn {
              font-family: $_roboto;
              background: #fdeb01;
              font-weight: 700;
              font-size: 18px;
              color: #000000;
              padding: 10px 0;
              width: 255px;
              height: 45px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0 !important;
              border: 1px solid transparent;
              transition: all 0.2s ease-out;
              text-transform: none;
              justify-content: flex-start;
              padding-left: 45px;
              &:hover,
              &:focus {
                box-shadow: none;
              }
              &:hover {
                border-color: #000000;
              }
            }
            .lnk_innr_bx {
              padding-left: 60px;
              display: flex;
              flex-direction: column;
              .www_lnk {
                margin: 25px 0 70px 0;
              }
              a {
                font-family: $_roboto;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                pointer-events: none;
                opacity: 0;
                &.www_lnk {
                  pointer-events: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
        .lft_bttm_bx {
          margin-top: 17px;
        }
        .nkts_bx {
          .links_bx button,
          .same_btn {
            background: #fc51ff !important;
          }
        }
        .ecc_bx {
          .links_bx button,
          .same_btn {
            background: #d8ae66 !important;
          }
        }
        .upcro_bx {
          .links_bx button,
          .same_btn {
            background: #5c9eff !important;
          }
        }
        .upck_bx {
          .links_bx button,
          .same_btn {
            background: #1de8f7 !important;
          }
        }
        .diatm_bx {
          .links_bx button,
          .same_btn {
            background: #32aadc !important;
          }
        }
        .upmtk_bx {
          .links_bx button,
          .same_btn {
            background: #a177ed !important;
          }
        }
        .emprdx_bx {
          .links_bx button,
          .same_btn {
            background: #cab3a0 !important;
          }
        }
        .bggrmnd_bx {
          .links_bx button,
          .same_btn {
            background: #bc8fff !important;
          }
        }
        .pbgb_bx {
          .links_bx button,
          .same_btn {
            background: #ffde31 !important;
          }
        }
        .vnyrd_bx {
          .links_bx button,
          .same_btn {
            background: #b4adff !important;
          }
        }
        .lnshrp_bx {
          .links_bx button,
          .same_btn {
            background: #e3f2ff !important;
          }
        }
        .rtbrdg_bx {
          .links_bx button,
          .same_btn {
            background: #51c1c8 !important;
          }
        }
        .strgi_bx {
          .links_bx button,
          .same_btn {
            background: #c1c2db !important;
          }
        }
        .deflect_bx {
          .links_bx button,
          .same_btn {
            background: #e74469 !important;
          }
        }
        .bfm_bx {
          .links_bx button,
          .same_btn {
            background: #ff804b !important;
          }
        }
      }
      .rght_bx {
        margin-left: 16px;
        .links_bx {
          background: #ffffff;
          border: 1px solid #9fa4b4;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 6px 0;
          button,
          .same_btn {
            font-family: $_roboto;
            background: #fdeb01;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
            padding: 0;
            width: 255px;
            height: 45px;
            border-radius: 0 !important;
            margin: 8px 0;
            text-transform: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 55px;
            border: 1px solid transparent;
            transition: all 0.2s ease-out;
            &:hover,
            &:focus {
              box-shadow: none;
            }
            &:hover {
              border-color: #000000;
            }
          }
          .lnk_innr_bx {
            padding-left: 100px;
            display: flex;
            flex-direction: column;
            // .www_lnk{
            //   margin: 25px 0 70px 0;
            // }
            a {
              font-family: $_roboto;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #000000;
              margin: 0 !important;
              pointer-events: none;
              opacity: 0;
              &.www_lnk {
                pointer-events: visible;
                opacity: 1;
              }
            }
          }
        }
      }
      .jgsw_bx {
        .links_bx button,
        .same_btn {
          background: #a0a0ff !important;
        }
      }
      .df_dngn {
        .links_bx button,
        .same_btn {
          background: #75ffef !important;
        }
      }
      .rua_bx {
        .links_bx button,
        .same_btn {
          background: #b3a0ff !important;
        }
      }
      .cat_bx {
        .links_bx button,
        .same_btn {
          background: #fed809 !important;
        }
      }
      .uptthr_bx {
        .links_bx button,
        .same_btn {
          background: #00ff73 !important;
        }
      }
      .prsm_bx {
        .links_bx button,
        .same_btn {
          background: #a0a0ff !important;
        }
      }
      .upbnb_bx {
        .links_bx button,
        .same_btn {
          background: #f3ba2f !important;
        }
      }
      .magin_bx {
        .links_bx button,
        .same_btn {
          background: #00e813 !important;
        }
      }
      .greed_bx {
        .links_bx button,
        .same_btn {
          background: #07e12a !important;
        }
      }
      .herchain_bx {
        .links_bx button,
        .same_btn {
          background: #44cbc4 !important;
        }
      }
      .klima_bx {
        .links_bx button,
        .same_btn {
          background: #fdf5ea !important;
        }
      }
      .moonbts_bx {
        .links_bx button,
        .same_btn {
          background: #dbf4f8 !important;
        }
      }
      .blkvd_bx {
        .links_bx button,
        .same_btn {
          background: #def782 !important;
        }
      }
      .sprtcs_bx {
        .links_bx button,
        .same_btn {
          background: #b29e6c !important;
        }
      }
    }
  }
}
.clck_view_btn button {
  background: #75ffee !important;
  color: #000000 !important;
  &:hover {
    background: #000000 !important;
    color: #ffffff !important;
  }
}
// .detail_slider_bx {
//   .jWCrSi{
//     background: #F4F4F4;
//     padding: 7px 10px 7px 10px;
//     height: 31px;
//   }
//   .kSMAC{
//     background: #59D7FF;
//     height: 17px;
//   }
// }
.HorizontalScroll .HorizontalScrollTrack .HorizontalScrollTrackInner:after {
  background-color: #000;
  opacity: 1;
}
.detail_slider_bx .ovrflw_bx > div > div > div + div {
  background: #f4f4f4;
  padding: 7px 10px 7px 10px;
  height: 31px;
}
.detail_slider_bx .ovrflw_bx > div > div > div + div div {
  background: #59d7ff;
  height: 17px;
}
.detail_slider_bx .ovrflw_bx > div > div > div + div div::before {
  content: "";
  position: absolute;
  left: 103%;
  top: -5px;
  height: 24px;
  width: 168px;
  background: url(../img/scroll_img.svg) no-repeat 0 0;
  opacity: 1;
  transition: all 0.2s ease-out;
}
.detail_slider_bx
  .ovrflw_bx
  > div
  > div
  > div
  + div
  div[style*="left: "]::before {
  opacity: 0;
}
.detail_slider_bx
  .ovrflw_bx
  > div
  > div
  > div
  + div
  div[style*="left: 0"]::before {
  opacity: 1;
}
@media screen and (max-width: 1105px) {
  .detail_main_bx .detail_slider_bx .three_dtl_bx .lft_bx .lft_tp_bx .links_bx {
    width: 260px;
    min-height: 236px;
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .lft_bx .lft_tp_bx {
    .img_bx {
      img {
        width: 365px;
      }
    }
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .rght_bx {
    .img_bx {
      img {
        width: 344px;
      }
    }
  }
  .detail_main_bx
    .detail_slider_bx
    .three_dtl_bx
    .lft_bx
    .lft_tp_bx
    .links_bx
    button {
    font-size: 11px;
  }
  .detail_main_bx
    .detail_slider_bx
    .three_dtl_bx
    .lft_bx
    .lft_tp_bx
    .links_bx
    .lnk_innr_bx {
    padding-left: 5px;
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .rght_bx .links_bx {
    .lnk_innr_bx {
      padding-left: 5px !important;
    }
    button {
      font-size: 11px;
    }
    .www_lnk {
      margin: 25px 0px 25px 0;
    }
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .rght_bx {
    margin-left: 14px;
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .lft_bx .lft_bttm_bx {
    margin-top: 15px;
  }
  .blck_bg_bx {
    margin: 25px 0 0 0;
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .rght_bx .links_bx button,
  .detail_main_bx .detail_slider_bx .three_dtl_bx .rght_bx .links_bx .same_btn {
    padding: 5px 0 5px 5px;
    font-size: 16px;
  }
  .detail_main_bx
    .detail_slider_bx
    .three_dtl_bx
    .lft_bx
    .lft_tp_bx
    .links_bx
    button,
  .detail_main_bx
    .detail_slider_bx
    .three_dtl_bx
    .lft_bx
    .lft_tp_bx
    .links_bx
    .same_btn {
    padding: 5px 0 5px 5px;
    font-size: 16px;
  }
}
@media screen and (max-width: 900px) {
  .detail_main_bx .detail_slider_bx .three_dtl_bx .lft_bx .lft_tp_bx .links_bx {
    width: 240px;
    min-height: 208px;
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .lft_bx .lft_tp_bx {
    .img_bx {
      img {
        width: 320px;
      }
    }
  }
  .detail_main_bx .detail_slider_bx .three_dtl_bx .rght_bx {
    .img_bx {
      img {
        width: 290px;
      }
    }
  }
}
.cards_cntnt_bx_main {
  .sldr_btn {
    min-width: 160px;
  }
}
// about page
.abt_main_box {
  padding: 120px 0 70px 0;
  .abt_innr_cntn {
    h2 {
      max-width: 230px;
      text-align: center !important;
    }
    .blue_h2 {
      background-color: #59d7ff;
    }
    h4 {
      font-weight: bold;
      font-size: 30px;
      line-height: 45px;
      color: $c_black;
      margin: 45px 0 20px 0;
    }
    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: $c_black;
    }
  }
  .our_team_txt {
    h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      text-transform: uppercase;
      color: $c_black;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $c_black;
    }
  }
  .our_team_boxes {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0 20px 35px 20px;
    background: rgba(156, 156, 156, 0.1);
    backdrop-filter: blur(17.9334px);
    img {
      margin-right: 30px;
      margin-left: -40px;
    }
    h4 {
      font-weight: bold;
      font-size: 23.3134px;
      line-height: 35px;
      color: $c_black;
    }
    p {
      font-weight: normal;
      font-size: 16.14px;
      line-height: 18px;
      color: $c_black;
    }
    .arrow_box {
      margin-left: auto !important;
      padding: 15px;
      background: rgba(156, 156, 156, 0.4);
      backdrop-filter: blur(17.9334px);
      .MuiSvgIcon-root {
        color: $c_black;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .abt_main_box {
    .our_team_boxes {
      padding: 10px;
      margin: 0 10px 35px 10px;
      img {
        margin-right: 12px;
        margin-left: -25px;
      }
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
      .arrow_box {
        padding: 10px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .abt_main_box {
    .abt_innr_cntn {
      .blue_h2 {
        width: 190px;
      }
      h4 {
        font-size: 22px;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .our_team_boxes {
      img {
        height: 70px;
      }
    }
  }
  .abt_main_box .abt_innr_cntn .blue_h2 {
  }
}
@media screen and (max-width: 575px) {
  .abt_main_box {
    padding: 60px 0 50px 0;
    .our_team_txt {
      h1 {
        font-size: 26px;
        line-height: 40px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .our_team_boxes {
      img {
        height: 90px;
      }
    }
  }
}
.services_box {
  background: url(../img/services_bg_2.png) no-repeat center 0;
  background-size: cover;
  position: relative;
  .blur_bg_box {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    padding: 35px;
    .services_innr_cntnt {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .def_same_blu_box {
        background: #59d7ff;
        backdrop-filter: blur(16.8437px);
        height: 100px;
        width: 100px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
      }
      h3 {
        font-weight: bold;
        font-size: 20.2124px;
        line-height: 30px;
      }
    }
  }
}
.fuel_main_box {
  padding: 60px 0;
  .fuel_innr_cntnt {
    display: flex;
    align-items: center;
    .fuel_blU_h2 {
      width: 225px;
      background: #59d7ff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .fuel_p_txt {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: $c_black;
    margin-top: 35px;
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      text-decoration-line: underline;
      color: $c_black;
    }
  }
}
@media screen and (max-width: 1199px) {
  .services_box .blur_bg_box .services_innr_cntnt h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .services_box {
    .blur_bg_box {
      padding: 20px;
      .services_innr_cntnt {
        .def_same_blu_box {
          margin-right: 10px;
        }
        h3 {
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
  .fuel_main_box {
    .fuel_innr_cntnt {
      img {
        width: 250px;
      }
    }
    .fuel_p_txt {
      font-size: 15px;
      line-height: 24px;
      a {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .services_box .blur_bg_box .services_innr_cntnt .def_same_blu_box {
    height: 80px;
    width: 80px;
  }
  .fuel_main_box {
    padding: 40px 0;
    .fuel_innr_cntnt {
      flex-direction: column;
      .fuel_blU_h2 {
        width: 180px;
      }
      img {
        margin-right: auto;
        margin-bottom: 30px;
      }
    }
    .fuel_p_txt {
      margin-top: 20px;
    }
  }
}
.prtnr_prjtct_main_bx {
  padding: 96px 0 140px 0;
  .main_hdng {
    background: #000000;
    max-width: 320px;
    width: 100%;
    padding: 6px 0 10px 20px;
    margin-bottom: 64px;
    h4 {
      font-family: $f_primary;
      font-weight: 300;
      font-size: 22px;
      line-height: 22px;
      text-transform: uppercase;
      color: $c_white;
      span {
        font-family: $_roboto !important;
        right: -86px;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        color: #59d7ff;
        text-transform: lowercase;
        position: relative;
        top: -6px;
      }
      b {
        display: block;
        font-weight: 700;
      }
    }
  }
  .prnt_boxes_bx {
    .sldr_box {
      margin-bottom: 20px;
    }
    .sldr_btn {
      min-width: 185px;
    }
  }
}
@media screen and (max-width: 1430px) {
  .prtnr_prjtct_main_bx {
    padding: 60px 0 30px 0;
    .main_hdng {
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 575px) {
  .prtnr_prjtct_main_bx {
    padding: 30px 0 30px 0;
  }
  .prtnr_prjtct_main_bx .main_hdng {
    padding: 0px 0 6px 4px;
    max-width: 280px;
    h4 {
      font-weight: 300;
      font-size: 20px;
    }
  }
  .prtnr_prjtct_main_bx .main_hdng h4 span {
    right: -68px;
    font-size: 15px;
    line-height: 13px;
    top: -3px;
  }
  .prtnr_prjtct_main_bx .main_hdng {
    margin-bottom: 20px;
  }
  .srvcttl {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500x) {
  .prtnr_prjtct_main_bx .prnt_boxes_bx .sldr_btn {
    min-width: 140px;
  }
}
.area .scrollarea-content {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  display: flex;
}
.service_sc {
  .MuiPaper-root {
    max-width: 750px !important;
    margin: 0 !important;
  }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 1340px !important;
  width: 100%;
}
.ifrm_div {
  padding: 56.25% 0 0 0;
  position: relative;
  width: 100%;
  max-width: 100%;
  .ifrm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.bitgr_tab_box {
  position: relative;
  .capt_left_p {
    position: absolute;
    left: 60px;
    top: 7px;
    font-weight: 300;
    font-size: 13.9885px;
    line-height: 21px;
    color: #000000;
  }
  .allc_right_p {
    position: absolute;
    right: 60px;
    top: 7px;
    font-weight: 600;
    font-size: 16.7624px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #000000;
    z-index: 9;
    img {
      margin-left: 3px;
    }
  }
  .allc_right_p_link:hover {
    color: #59d7ff;
    img {
      filter: invert(84%) sepia(24%) saturate(5122%) hue-rotate(167deg)
        brightness(102%) contrast(104%);
    }
  }
  .span_bg_n {
    span {
      background-color: transparent;
    }
    .MuiTabs-flexContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .stk_btn {
    background: #CACACA !important;
    backdrop-filter: blur(2.25713px) !important;
    backdrop-filter: blur(4.51425px);
    border-radius: 37.6188px;
    font-family: $_roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    margin-right: 15px;
    width: 233px;
    min-height: 38.39px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    position: relative;
    padding-left: 20px;
    .b_img {
      height: 37px;
      width: 37px;
      background: black;
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .Mui-selected {
    background: rgba(202, 202, 202, 0.4);
    backdrop-filter: blur(4.51425px);
    border-radius: 37.6188px;
    color: #000000 !important;
    .b_img {
      img {
        filter: invert(66%) sepia(98%) saturate(1432%) hue-rotate(172deg)
          brightness(111%) contrast(102%);
      }
    }
  }
  .margi_r {
    margin-right: 0px;
    min-width: 192px;
  }
}
// .min_h_box {
//   min-height: 253px;
// }
.man_box_colps {
  background: #f5f5f5;
  backdrop-filter: blur(15.0475px);
  // max-width: 286px;
  // min-height: 820px;
  width: 100%;
  margin-top: 21px;
  position: relative;
  border: 1px solid #9fa4b4;
  .img-fluid {
    max-width: 100%;
    width: 100%;
  }
  video {
    max-width: 100%;
    width: 100%;
    display: block;
  }
  .the_fir_h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-top: 16px;
    margin-bottom: 17px;
  }

  .for_btn {
    margin: 0 auto;
    border-color: transparent;
    font-family: $f_primary;
    font-weight: 700;
    font-size: 10.064px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    background: #dddddd;
    width: 172.7px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    position: relative;
    bottom: -15px;
    &:focus {
      box-shadow: none;
    }
    // &:hover {
    //   background: #59d7ff;
    // }
  }
  .bit_col_p {
    font-family: $_roboto !important;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #949494;
    max-width: 249px;
    margin: 0 auto;
    min-height: 58px;
    display: flex;
  }
  // .bit_col_p_02{
  //   margin-bottom: 23px;
  // }
}
.jut_con_c {
  justify-content: center !important;
}
.totel_img_box {
  background: #eeeeee;
  backdrop-filter: blur(15.0475px);
  min-height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 9px;
  width: 100%;
  h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    span {
      font-weight: 700;
      color: #59d7ff;
    }
  }
  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    span {
      color: #59d7ff;
    }
  }
}
.pading_tab > div > div {
  padding: 0;
}
.vew_aro_btn {
  background: #59d7ff;
  width: 259.57px;
  height: 52.67px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto 82px auto;
  border-color: transparent;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background: #59d8ffa1;
    border-color: transparent;
  }
  .viw_aro {
    font-family: $f_primary;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #000000;
    img {
      margin-left: 4px;
    }
  }
  p {
    font-family: $_roboto !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    position: relative;
    bottom: -5px;
  }
}
@media screen and (max-width: 1199px) {
  .man_box_colps .the_fir_h3 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 14px;
    margin-bottom: 15px;
  }
  .totel_img_box {
    padding: 0px 10px;
  }
  .totel_img_box h5,
  .totel_img_box h4 {
    font-size: 10px;
    line-height: 18px;
  }
}
@media screen and (max-width: 991px) {
  .man_box_colps {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 21px auto;
  }
  .bitgr_tab_box .capt_left_p {
    display: none;
  }
  .bitgr_tab_box .allc_right_p {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 136px;
  }
  .vew_aro_btn {
    margin: 30px auto 10px auto;
  }
  .nft_str_box {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
@media screen and (max-width: 520px) {
  .bitgr_tab_box .stk_btn {
    margin-right: 5px;
    width: 216px;
  }
  .ris_btn_two > div > div > div {
    flex-direction: column;
  }
  .bitgr_tab_box .margi_r {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1650px) {
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom {
    padding: 20px 26px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom h4 {
    font-size: 18px;
    line-height: 28px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom p {
    font-size: 16px;
    line-height: 20px;
    min-height: 62px;
  }
}
@media screen and (max-width: 1400px) {
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom {
    padding: 20px 24px;
  }
}
@media screen and (max-width: 1279px) {
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom {
    padding: 18px 20px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom h4,
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom .h4 {
    font-size: 17px;
    line-height: 20px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom p {
    font-size: 14px;
    line-height: 20px;
    min-height: 62px;
  }
  .cards_cntnt_bx_main .sldr_btn {
    min-width: 142px;
    height: 32px;
    font-size: 13px !important;
    line-height: 17px !important;
  }
}
@media screen and (max-width: 1199px) {
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom {
    padding: 16px 13px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom h4,
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom .h4 {
    font-size: 15px;
    line-height: 18px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom p {
    font-size: 12px;
    line-height: 16px;
    min-height: 48px;
  }
}
.vist_stor_titl {
  font-family: $f_primary;
  font-weight: 300;
  font-size: 22px !important;
  line-height: 22px !important;
  text-transform: uppercase;
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom p {
    min-height: 0px;
  }
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom h4,
  .prjct_lstng_main_bx .cards_cntnt_bx .sldr_box_bottom .h4 {
    margin-bottom: 10px;
  }
  .vist_stor_titl {
    max-width: 280px;
    font-size: 20px !important;
    line-height: 20px !important;
  }
  .def_bl_ttl {
    margin-bottom: 20px !important;
  }
  .def_bl_ttl .cmng_sn_txt {
    font-size: 15px;
    line-height: 14px;
  }
}
.detail_main_bx
  .detail_slider_bx
  .three_dtl_bx
  .lft_bx
  .lft_tp_bx
  .links_bx
  .on_hover_black_stroke {
  border: 1px solid transparent;
  transition: all 0.2s ease-out;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    border: 1px solid #000000;
    box-shadow: none !important;
  }
}
#logo-container svg {
  width: 250px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  #logo-container svg {
    width: 150px;
    height: auto;
  }
}
.heit_20px_for_space {
  height: 20px;
}
.nft_gallary_main {
  padding-top: 160px;
  background: #ffffff;
  .welcm_galary_text {
    h2 {
      font-family: $f_primary;
      font-weight: 250;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      span {
        font-weight: 700;
      }
    }
    p {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-top: 8px;
    }
  }
}
.nft_str_box_gallary {
  padding: 0;
  .man_box_colps {
    margin-bottom: 16px;
  }
  .bitgr_tab_box .stk_btn {
    width: 185px;
    height: 38.39px;
    text-transform: capitalize;
    font-family: $_roboto !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000000;
  }
  .ris_btn_two {
    margin-bottom: 50px;
    margin-top: -15px;
  }
  .def_bl_ttl {
    margin-bottom: 0 !important;
    position: relative;
    bottom: -60px;
    .cmng_sn_txt {
      color: #59d7ff;
      margin-left: 71px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .nft_str_box_gallary .def_bl_ttl {
    max-width: 290px;
    padding: 6px 0 10px 12px;
  }
}
@media screen and (max-width: 1099px) {
  .nft_str_box_gallary .ris_btn_two {
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .nft_str_box_gallary .def_bl_ttl {
    margin-bottom: 12px !important;
    margin-top: 40px;
    bottom: 0;
  }
}
.nft_for_clint_main {
  margin-top: 120px;
  padding-bottom: 100px;
  .black_box_h {
    padding: 8px 20px 8px 30px;
    max-width: 320px;
    width: 100%;
    background: #000000;
    font-family: $f_primary;
    font-weight: 300;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 45px;
    span {
      font-family: $_roboto !important;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      text-align: right;
      color: #59d7ff;
      margin-left: 50px;
      text-transform: lowercase;
    }
    b {
      font-weight: 700;
    }
  }
  .nft_clnt_box {
    display: flex;
    flex-direction: column;
    max-width: 290px;
    margin: 0 auto;
    width: 100%;
    background: #f5f5f5;
    border: 1px solid #9fa4b4;
    backdrop-filter: blur(15.0475px);
    margin-bottom: 18px;
    img {
      max-width: 100%;
    }
    .text_btm_box {
      padding: 18px 7px 8px 7px;
      min-height: 96px;
      p {
        font-family: $_roboto !important;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: #949494;
        margin-bottom: 8px;
      }
    }
    button {
      background: transparent;
      border: none;
      font-family: $f_primary;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-decoration-line: underline;
      color: #000000;
      margin: 0 auto;
      display: flex;
      height: 18px;
      padding: 0;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: #59d7ff;
      }
    }
  }
  .nft_clnt_show_more_btn_prnt {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 172px;
      height: 33px;
      background: #59d7ff;
      border: none;
      border-radius: 0;
      margin-top: 30px;
      font-family: $f_primary;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #000000;
      border: 1px solid transparent;
      transition: all 0.2s ease-out;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background: #baefff;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .nft_gallary_main {
    padding-top: 130px;
  }
  .nft_for_clint_main {
    margin-top: 90px;
    padding-bottom: 80px;
  }
  .nft_for_clint_main .nft_clnt_show_more_btn_prnt button {
    margin-top: 20px;
  }
}
@media screen and (max-width: 575px) {
  .nft_for_clint_main {
    margin-top: 50px;
    padding-bottom: 45px;
  }
  .nft_for_clint_main .black_box_h {
    padding: 8px 15px 8px 20px;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 24px;
    max-width: 280px;
  }
  .nft_for_clint_main .black_box_h span {
    font-size: 15px;
    line-height: 12px;
    margin-left: 43px;
  }
  .nft_for_clint_main .nft_clnt_show_more_btn_prnt button {
    margin-top: 6px;
  }
}
.cntnt_crtn_frm_ash {
  max-width: 470px;
  margin: 0 auto;
}
.videoplayer_padding {
  padding: 0;
  .MuiDialog-paper {
    max-width: 1340px !important;
    width: 100%;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
}
.bit_graphix_text_img {
  position: absolute;
  z-index: 1;
  top: 398px;
  max-width: 100%;
  @media screen and (max-width: 1600px) {
    top: 310px;
  }
  @media screen and (max-width: 991px) {
    top: 230px;
  }
}
.nft_str_box_bg_color {
  background: #0d2f47;
  padding-bottom: 74px;
  padding-top: 68px;
  .vew_aro_btn {
    margin-bottom: 0;
  }
  .bg_changed_ash {
    background: #596d7b;
    .cmng_sn_txt {
      color: #ffda15;
    }
  }
  .cnnctwllt_btn span {
    background: #596d7b;
  }
  .bitgr_tab_box .capt_left_p {
    color: #ffffff;
  }
  .bitgr_tab_box .allc_right_p_link {
    color: #ffffff;
    cursor: pointer;
  }
  .bitgr_tab_box .allc_right_p_link:hover {
    color: #59d7ff;
  }
  .bitgr_tab_box .allc_right_p_link:hover img {
    filter: invert(25%) sepia(24%) saturate(5122%) hue-rotate(167deg)
      brightness(102%) contrast(104%);
  }
  .vew_aro_btn p {
    color: #000000;
  }
}
.service_sc {
  .z_index {
    position: relative;
    z-index: 1;
  }
  .in_s_box {
    background: #ffffff;
  }
}
.service_sc::before {
  content: "";
  position: absolute;
  top: -61px;
  left: 0;
  height: 390px;
  width: 100%;
  background: #ff9315;
}
.dev_team_sc {
  min-height: 375px;
  position: relative;
  background: #0d2f47;
  width: 100%;
  display: flex;
  align-items: center;
  .bit_img {
    position: absolute;
    left: 0;
    top: 5px;
    mix-blend-mode: normal;
    opacity: 0.4;
  }
  .dev_team_cntnt {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    .headeingtext {
      h3 {
        font-family: $f_primary;
        font-style: normal;
        font-weight: 700;
        font-size: 61px;
        line-height: 60px;
        text-transform: uppercase;
        color: #59d7ff;
      }
      p {
        font-family: $f_primary;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        text-transform: uppercase;
        color: #59d7ff;
      }
    }
    .details_p {
      font-family: $f_primary;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 116%;
      text-transform: uppercase;
      color: #ffffff;
      max-width: 802px;
      margin-left: 38px;
    }
  }
}
@media screen and (max-width: 1099px) {
  .dev_team_sc .dev_team_cntnt .details_p {
    font-size: 25px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 991px) {
  .dev_team_sc .dev_team_cntnt {
    flex-direction: column;
  }
  .dev_team_sc .dev_team_cntnt .details_p {
    font-size: 25px;
    margin-left: 0px;
    text-align: center;
    max-width: 783px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .dev_team_sc .dev_team_cntnt .details_p br {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .dev_team_sc .dev_team_cntnt .headeingtext h3,
  .dev_team_sc .dev_team_cntnt .headeingtext .h3 {
    font-size: 52px;
    line-height: 52px;
    text-align: center;
  }
  .dev_team_sc .dev_team_cntnt .headeingtext p {
    font-size: 33px;
    line-height: 32px;
  }
  .dev_team_sc .dev_team_cntnt .details_p {
    font-size: 19px;
    max-width: 783px;
  }
}

.header-top {
  position: fixed;
  top: 0;
  width: 100%;
}

.dropdown-menu.show {
  background: linear-gradient(
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.8)
  ) !important;
}

.rght_bx,
.lft_bx {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.nav-link {
  margin-right: 20px;
}

.metamask-logo {
  margin-top: 60%;
  margin-left: 30%;
}

.player {
  position: absolute;
  z-index: 50;

  video {
    position: absolute;
    z-index: 100;
  }
}

// .player::before {
//   content: ""; // ::before and ::after both require content
//   position: absolute;
//   z-index: 50;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-image: linear-gradient(#000, #000);
//   opacity: 0.7;
// }

//   // video {
//   //   width: 250px !important;
//   // }
.playBtn {
  width: 85%;
  margin-left: auto;
  transform: translateY(-30px);
  @media (max-width: 1430px) {
    transform: translate(-75px, -85px);
  }
  @media (max-width: 1230px) {
    transform: translate(-130px, -60px);
  }
  @media (max-width: 991px) {
    transform: translate(-206px, -133px);
  }
  @media (max-width: 840px) {
    transform: translate(-179px, -87px);
  }
}

.App {
  overflow: hidden; // Added because there is some container overflowing but I can't find it - feel free to remove if you know the fix
}




/**/

.new_login_main {
  background: url(../img/login_bg.jpeg) no-repeat center;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  .login_man_box {
    .login_h1_box {
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 94px;
        text-align: center;
        color: #536765;
        font-family: $f_primary !important;
      }
      span {
        color: #59D7FF;
      }
    }
  }
  .login_pop_box {
    background: #FFFFFF;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    max-width: 547px;
    width: 100%;
    margin: 76px auto 0 auto;
    padding: 20px;
  }
}

.landing_page {
  .header, .footer {
    display: none;
  }
}




.login_pop_box {
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(138, 248, 173, 0.2);
  border-radius: 30px;
  max-width: 547px;
  width: 100%;
  margin: 76px auto 0 auto;
  padding: 20px;
}
.back_btn_box a {
  font-family: $f_primary !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #696969;
  background: transparent;
  box-shadow: none !important;
  padding: 0;
  transition: all 0.2s ease-out;
}
.back_btn_box {
  display: flex;
  align-items: center;
}
.back_btn_box a img {
  margin-right: 8px;
}
.back_btn_box a:hover {
  background: transparent;
  color: #59D7FF;
}
.back_btn_box a:hover img {
  filter: invert(99%) sepia(60%) saturate(4447%) hue-rotate(180deg) brightness(141%) contrast(83%);
}
.login_h3_btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login_h3_btn_box h3 {
  font-family: $f_primary !important;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #536765;
  margin-top: 19px;
}
.login_h3_btn_box h4 {
  font-family: $f_primary !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 55px;
  margin-bottom: 55px;
  color: #536765;
}
.login_h3_btn_box button {
  font-family: $f_primary !important;
  font-weight: 500;
  font-size: 23.1556px;
  line-height: 33px;
  color: #536765;
  margin-bottom: 80px;
  max-width: 387px;
  width: 100%;
  height: 66px;
  position: relative;
  box-shadow: none !important;
  transition: all 0.2s ease-out;
  border: 1px solid #59D7FF;
  border-radius: 20px;
}
.login_h3_btn_box button:hover {
  background: transparent;
  color: #59D7FF !important;
}
.login_h3_btn_box button img {
  margin-right: 8px;
}
.login_h3_btn_box p {
  font-family: $f_primary !important;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  color: #858585;
}
.login_h3_btn_box p a {
  color: #0993ec;
  margin-left: 10px;
  transition: all 0.2s ease-out;
}
.login_h3_btn_box p a:hover {
  color: #536765;
}

@media screen and (max-width: 1280px) {
  .new_login_main .login_pop_box {
    margin: 16px auto 0 auto;
  }
}
@media screen and (max-width: 991px) {
  .new_login_main .login_man_box .login_h1_box h1 {
    font-size: 44px;
    line-height: 74px;
  }
}
@media screen and (max-width: 575px) {
 .login_man_box {
  padding-left: 40px;
 }
 .new_login_main .login_man_box .login_h1_box h1 {
  font-size: 24px;
  line-height: 34px;
 }
 .new_login_main .login_pop_box {
  max-width: 100%;
 }
 .back_btn_box a img {
  margin-right: 3px;
  }
  .back_btn_box a {
    font-size: 14px;
    line-height: 20px;
  }
  .login_h3_btn_box h3 {
    font-size: 20px;
    line-height: 22px;
    margin-top: 9px;
  }
  .login_h3_btn_box h4 {
    font-size: 14px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .login_h3_btn_box button {
    font-size: 16px;
    line-height: 33px;
    margin-bottom: 32px;
    max-width: 100%;
    width: 100%;
    height: 46px;
  }
}



/**/


.disebar_main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  // padding-left: 34px !important;
  padding-top: 65px !important;
  @media screen and (max-width: 575px) {
    padding-top: 35px !important;
  }
  a {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-out;
    padding-left: 34px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
      // &:first-child {
      //   padding-top: 15px;
      // }
      &:last-child {
        margin-bottom: 0;
      }
      .sdbr_sml_img_bx {
        //
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
        border-radius: 8px;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-out;
      }
      h2 {
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #828282;
        margin-left: 7px;
        transition: all 0.3s ease-out;
      }
    &:hover, &.active {
      h2 {
        color: #000;
      }
      .sdbr_sml_img_bx {
        background: #59D7FF;
      }
      img {
        filter: brightness(0) contrast(100);
      }
    }
  }
}
.sidebar_logo {
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 0;
  img {
    width: 100%;
    max-width: 165px;
  }
}
@media screen and (max-width: 1199px) {
  .right_two_btns  {
    padding-left: 34px !important;
    padding-top: 15px;
  }
}
@media screen and (max-width: 575px) {
  // .disebar_main {
  //   padding-left: 24px !important;
  //   padding-top: 34px !important;
  // }
  // .disebar_main a {
  //   margin-bottom: 18px;
  // }
}

.sidebar_nl_main {
  position: relative;
  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    background-color: #F3F3F3 !important;
    box-shadow: -2px 0px 30px rgb(0 0 0 / 25%) !important;
  }
  .appbar_inr {
    background: transparent;
    box-shadow: none;
  }
  .toolbar_sdbr {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    background: #f6fcfc;
    padding-bottom: 16px;
    .right_two_btns {
      display: flex;
      align-items: center;
    }
    .dark_laight_btn {
      background: #FFFFFF;
      box-shadow: 0px 4px 30px rgb(0 0 0 / 15%);
      border-radius: 35.8333px;
      width: 100%;
      height: 36px;
      max-width: 149px;
      margin-right: 22px;
      .light_dark_switch {
        margin: 0 6px;
      }
      @media screen and (max-width: 1199px) {
        margin-right: 0;
        position: fixed;
        bottom: 20px;
        right: 14px;
      }
    }
     
  }

  .tgl_logo_mx_bx {
    margin-right: auto;
    display: flex;
    align-items: center;
    .bg_none_logo {
      background: transparent;
      padding: 0;
      @media screen and (min-width: 1199px) {
        display: none;
      }
    }
  }
  .toggle_btn {
    background: #fff;
    svg {
      color: #000;
    }
  }
}
.mike_mayer_btn {
  background: #59D7FF !important;
  background-color: #59D7FF !important;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 15%) !important;
  border-radius: 35.8333px !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  max-width: 137.96px !important;
  height: 35.83px !important;

  h5 {
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 10.75px;
    line-height: 13px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 6px 0 6px;
    white-space: nowrap;
  }
}
.mayer_slct {
  .MuiSelect-select.MuiSelect-outlined {
    padding: 0;
    padding-right: 0 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSvgIcon-root {
    display: none;
  }
}
@media screen and (min-width: 460px) {
  .open_btn_1199 {
    display: none !important;
  }
}
@media screen and (max-width: 460px) {
  .dspl_none_1199 {
    display: none !important;
  }
}

.dflt_h1_usr_list {
  font-family: $f_primary !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 37px !important;
  color: #000000 !important;
  margin-bottom: 18px !important;
  @media screen and (max-width: 991px) {
    font-size: 24px !important;
    line-height: 30px !important;
    margin-bottom: 12px !important;
    margin-top: 30px !important;
  }
  @media screen and (max-width: 575px) {
    font-size: 22px !important;
    line-height: 28px !important;
    margin-bottom: 12px !important;
    margin-top: 18px !important;
  }

}

.sidebar_main_prnt {
  background: url(../img/user_list_bgg.jpg) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  padding-left: 40px !important;
  overflow-x: auto;
  .MuiToolbar-regular {
    padding-right: 0;
    padding-left: 0;
  }
  .usr_list_in_bx {
    background: #FFFFFF;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 18px 28px 28px 22px;
    overflow-x: auto;
    .usr_list_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid #E0E0E0 !important; 
      padding-bottom: 18px;
      h2 {
        font-family: $f_primary !important;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #000000;
      }
      .right_crte_btn {
        display: flex;
        align-items: center;
        button, a {
          transition: all 0.2s ease-out;
          &:last-child {
            margin-right: 0;
          }
        }
        .archive_btn {
          background: #FFFFFF;
          border: 1px solid #59D7FF;
          box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
          border-radius: 35.8333px;
          width: 100%;
          height: 36px;
          min-width: 194px;
          margin-right: 24px;
          padding: 0 10px;
          transition: all 0.3s ease-out;
          display: flex;
          align-items: center;
      
          h6 {
            margin-left: 8px;
            font-family: $f_primary !important;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            transition: all 0.3s ease-out;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        .create_usr_btn {
          background: transparent;
          display: flex;
          align-items: center;
          width: 100%;
          height: 36px;
          min-width: 194px;
          margin-right: 24px;
          padding: 0 10px;
          transition: all 0.3s ease-out;
          h6 {
            font-family: $f_primary !important;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #59D7FF;
            white-space: nowrap;
            margin-left: 8px;
            transition: all 0.3s ease-out;
          }
          img {
            transition: all 0.3s ease-out;
          }
          &:hover {
            h6 {
              color: #000;
            }
            img {
              filter: brightness(0);
            }
          }
        }
      }
    }
  }
  .usr_list_in_bx_v2 {
    padding: 18px 28px 0px 22px;
  }
}
.team_nft_main .usr_list_in_bx .usr_list_header {
  border-bottom: none !important;
}
@media screen and (max-width :1440px) {
  .sidebar_main_prnt {
    padding: 14px !important;
    padding-left: 20px !important;
  }
}
@media screen and (max-width : 768px) {
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn .create_usr_btn {
    min-width: 154px;
  }
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn .archive_btn {
    min-width: 154px;
    margin-right: 12px;
  }
}
@media screen and (max-width : 575px) {
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn .create_usr_btn {
    min-width: 154px;
  }
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn .archive_btn {
    min-width: 154px;
    margin-bottom: 4px;
    margin-right: 0;
    font-size: 14px;
  }
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn {
    flex-direction: column;
  }
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn .archive_btn h6 {
    font-size: 14px;
  }
  .sidebar_main_prnt .usr_list_in_bx .usr_list_header .right_crte_btn .create_usr_btn h6 {
    font-size: 14px;
  }
}


.inr_table_user {
  padding: 18px 0 80px 0;
  .user_table {
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0 15px;
   .user_thead {
      tr {
        th {
          font-family: $f_primary !important;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 10px;
          color: #000000;
          padding-bottom: 18px;
          &:first-child {
            padding-left: 16px;
          }
        }
      }
   }
   .tbody_user {
    tr {
      td {
        background: #EEEEEE;
        padding-top: 0px;
        padding-bottom: 0px;
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        &:first-child {
          border-radius: 10px 0 0 10px;
          padding-left: 18px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
          padding-right: 18px;
        }
        .drp_dwn_main {
          width: 100%;
          max-width: 30px;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
          }
          
        }
      }
    }
   }
  }
}



.menu_right_dots {
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    right: 5% !important;
    top: 314px !important;
    left: auto !important;
    background: rgb(255, 255, 255);
    box-shadow: rgb(136 136 136 / 30%) 0px 12.2293px 61.1465px;
    border-radius: 12.2293px;
    width: 192px;
    overflow-y: hidden;
    min-height: 90px;
    @media screen and (max-width: 1440px) {
      right: 5% !important;
      left: auto !important;
    }
  }
  ul {
    li {
      border-bottom: 1px solid #CECECE;
      padding-bottom: 12px;
      padding-top: 12px;
      &:last-child {
        border-bottom: none;
      }
      h4 {
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        margin-left: 12px;
      }
    }
  }
}



@media screen and (max-width: 1500px) {
  .inr_table_user {
    padding: 18px 0 90px 0;
    overflow-x: auto;
  }
  .inr_table_user .user_table {
    border-collapse: separate !important;
    border-spacing: 0 15px;
    min-width: 1200px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar_main_prnt .usr_list_in_bx {
    padding: 14px 14px 30px 14px;
    border-radius: 16px;
  }
  .inr_table_user {
    padding: 18px 0 30px 0;
  }
}

.tabl_pgntn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  ul {
    li {
      button {
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        background: transparent;
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        padding: 0;
        @media screen and (max-width:405px) {
          min-width: 21px;
          height: 26px;
        }
      }
      .Mui-selected {
        background-color: transparent !important;
        border: 1px solid #59D7FF !important;
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}

/**/

.user_modal_inr_main {
  background: #FFFFFF;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  width: 100%;
  max-width: 936px;
  margin: 60px auto 60px auto;
  @media screen and (max-width: 767px) {
    width: 98% !important;
  }
  // min-height: 1000px;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: rgba(211, 211, 211, 0.6);
  backdrop-filter: blur(15px);
}
.mdl_hdr_usr {
  display: flex;
  align-items: center;
  padding: 40px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  h1 {
    font-family:$f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    margin: 0 auto;
  }
}
button.back_btn_usr {
  font-family:$f_primary !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  position: absolute;
  transition: all 0.2s ease-out;
  left: 40px;
  text-transform: inherit;
  img {
    margin-right: 8px;
    transition: all 0.2s ease-out;
  }
  &:hover {
    background: transparent;
    color: #59D7FF;
    img {
         filter: invert(100%) sepia(100%) saturate(4447%) hue-rotate(90deg) brightness(141%) contrast(83%);
    }
  }
}
.usr_mdl_body_prnt {
  h1 {
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}
.user_inpts {
  padding: 46px 80px 0px 80px;
  margin-bottom: 70px;
}
.slct_bx_crte_usr {
  .slct_main_usr {
    .cstm_slct__control {
      border: none;
      border-color: transparent !important;
      border-bottom: 1px solid #000 !important;
      border-radius: 0;
      outline: none !important;
      box-shadow: none !important;
      &:hover, .focus {
        outline: none !important;
        box-shadow: none !important;
      }
      .cstm_slct__indicator-separator {
        display: none ;
      }

    }
    .cstm_slct__placeholder {
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 300;
      font-size: 16.5789px;
      line-height: 17px;
      color: #000000;
    }
    .cstm_slct__value-container {
      padding-left: 0;
    }
  }
}
.cstm_input {
  .MuiFormControl-root {
    width: 100%;
    border-bottom: 1px solid #000 !important;
    .MuiInput-underline {
      border: none !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      &:hover {
       
      }
      &::before {
        border-bottom: none !important;
        outline: none !important;
      }
      &::after {
        border-bottom: none !important;
      }
    }

    .MuiInputLabel-root {
      .mui-focused {
        color: #000 !important;
      }
    }
    label {
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 300;
      font-size: 16.5789px;
      line-height: 17px;
      color: #000000;
    }
  }
  input {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.slct_grd_cstm {
  padding-top: 35px !important;
}
.clastm_grid_prnt {
  padding-top: 24px !important;
}

@media screen and (max-width: 575px) {
  .clastm_grid_prnt, .slct_grd_cstm  {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

/**/
.wlt_tkn_blckchain {
  background: #F6F6F6;
  padding: 20px 80px;
  margin-bottom: 18px;
  h2 {
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
}

.wlt_inr_bxs {
  padding: 0 18px;
  display: flex;
  align-items: center;
  padding-bottom: 58px !important;
  .ds_flex {
    display: flex;
    align-items: center;
    
  }
  .slct_main_usr {
    .cstm_slct__placeholder {
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
    }
  }
}
// .MuiModal-root {
//   overflow: hidden auto !important;
// }

.MuiModal-root {
  overflow-y: auto;
}
.MuiBackdrop-root {
  pointer-events: none;
}
.MuiDrawer-root {
  .MuiBackdrop-root {
    pointer-events: visible ;
  }
}

.wlt_name_bx {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  height: 41px;
  input {
    padding: 6px;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    height: 30px;
    font-family:$f_primary !important;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
}
.slct_wlt_name {
  .slct_main_usr .cstm_slct__control {
    border: 1px solid #000 !important;
    border-radius: 6px;
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .cstm_slct__placeholder {
    font-family:$f_primary !important;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
}
.wlt_adrs_input {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  height: 41px;
  input {
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    font-family:$f_primary !important;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
}

.wlt_inpt_prnt {
  display: flex;
  align-items: center;
  .wlt_adrs_input {
    display: flex;
    align-items: center;
    input {
      border: none;
      outline: none;
      box-shadow: none;
      border-radius: 0;
      font-family:$f_primary !important;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      width: 100%;

    }
  }
  .delete_ic_bx {
    margin-left: 5px;
  }
}


@media screen and (max-width: 575px) {
  .mdl_hdr_usr {
    padding: 20px;
  }
  button.back_btn_usr {
    font-size: 14px;
    left: 10px;
  }
  .mdl_hdr_usr h1 {
    font-size: 22px;
    line-height: 27px;
    margin-left: auto !important;
    margin: inherit;
  }
  .usr_mdl_body_prnt h1{
    font-size: 22px;
    line-height: 26px;
    margin-top: 30px;
  }
}

@media screen and (max-width :767px) {
  .user_inpts {
    padding: 30px;
  }
  .wlt_tkn_blckchain h2 {
    font-size: 14px;
    line-height: 16px;
  }
  .wlt_tkn_blckchain {
    margin-bottom: 8px;
  }
}

.xs_1_only {
  max-width: 3.333333% !important;
  flex-basis: 3.333333% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    margin-right: 0;
  }
}
.frst_wlt_nme {
  max-width: 21.666667% !important;
  flex-basis: 21.666667% !important;
}
.wlt_inpt_prnt {
  max-width: 38.333333% !important;
  flex-basis: 38.333333% !important;
}
.radio_cstm {
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #59D7FF !important; 
  }
}

@media screen and (max-width: 991px) {
  .wlt_tkn_blckchain {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 767px) {
  .frst_wlt_nme, .wlt_inpt_prnt{
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .wlt_inr_bxs {
    .xs_1_only, .clastm_grid_prnt {
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
    .clastm_grid_prnt {
      padding-top: 14px !important;
    }
  }
}
@media screen and (max-width: 575px) {
  .wlt_tkn_blckchain {
    padding: 20px 12px;
  }
}

.new_paymnt_btn_bx {
  padding: 0 80px 64px 80px;
  button {
    border: 1px solid #59D7FF;
    border-radius: 10px;
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #59D7FF;
    width: 100%;
    padding: 20px 0;
  }
}
.user_mdl_ftr {
  border-top: 1px solid #E0E0E0;
  padding: 38px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 100%;
    max-width: 210px;
    height: 54px;
    border-radius: 57.8371px;
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
   transition: all 0.3s ease-out;
   padding: 10px 0;
   &:hover {
    opacity: 0.8;
   }
  }
  .cncl_btn_usr {
    border: 1px solid #59D7FF;
    color: #000000;
    margin-right: 22px;
    &:hover {
      opacity: 0.8;
     }
  }
  .create_btn_usr {
    background: #59D7FF;
    opacity: 0.3;
    color: #FFFFFF;
    &:hover {
      opacity: 0.8;
      background: #59D7FF;
     }
  }
}


@media screen and (max-width: 767px) {
  .wlt_inr_bxs {
    padding-bottom: 28px !important;
  }
  .new_paymnt_btn_bx {
    padding: 0 20px 34px 20px;
  }
  .new_paymnt_btn_bx button {
    padding: 14px 0;
  }
}

@media screen and (max-width: 575px) { 
  .new_paymnt_btn_bx button {
    font-size: 15px;
    line-height: 16px;
  }
  .user_mdl_ftr button {
    max-width: 140px;
    height: 44px;
    border-radius: 27.8371px;
    font-size: 14px;
  }
  .user_mdl_ftr .cncl_btn_usr {
    margin-right: 12px;
  }
}
.slct_main_usr {
  .cstm_slct__menu {
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgb(136 136 136 / 30%);
    padding: 14px 0;
    .cstm_slct__option {
      border-bottom: 1px solid #E3E3E3;
      background: transparent !important;
      color: #000;
    }
    .cstm_slct__option.cstm_slct__option--is-focused {
      background: url(../img/light_check.svg) no-repeat 94% 50% !important; 
    }
  }
}

.slct_wlt_name {
  .slct_main_usr .cstm_slct__menu .cstm_slct__option {
    padding: 10px 4px;
    
  }
  .cstm_slct__placeholder {
    margin-left: 10px;
  }
  .cstm_slct__single-value {
    margin-left: 10px;
  }
}
.eth_bx_menu {
  display: flex;
  align-items: center;
  p{
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 500;
    font-size: 10.5789px;
    line-height: 17px;
    color: #000000;
    margin-left: 5px;
  }
}


/**/
// archive user
.right_ckbx_main {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
  svg {
    // background: #FFFFFF;
    // border: 1px solid #000000;
    // border-radius: 4px;
    path {
      fill: #000 !important;
    }
  }
  .Mui-checked {
    svg {
      path {
        fill: #59D7FF !important;
      }
    }
  }
}


/**/

// editNFT

.flx_clm_bx {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  button, a {
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    padding: 0;
    text-transform: inherit;
    margin-top: 14px;
    img {
      margin-right: 8px;
    }
  }
}



.edit_nft_main {
  background: #FFFFFF;
  border: 1px solid #9E9E9E;
  border-radius: 10px;
  margin: 40px auto 50px auto;
  max-width: 942px;
  width: 100%;
  .inr_nft_body {
    padding: 12px 36px 12px 12px;
    display: flex;
    align-items: flex-end;
    .black_mmbr_bx {
      flex-basis: 40%;
      background: #070907;
      border: 1px solid #59D7FF;
      border-radius: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 150px 0 30px 0;
      img {

      }
      h4 {
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        background: linear-gradient(273.39deg, #EA8BE0 24.3%, #D479FF 98.03%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin: 114px 0 36px 0;
      }
      button {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        box-shadow: 0px 4px 24px rgb(87 223 255 / 60%);
        border-radius: 12.3636px;
        padding: 8px 28px;
        transition: all 0.2s ease-out;
        &:hover {
          opacity: 0.8;
        }
        p {
          font-family: $f_primary !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-align: right;
          color: #59D7FF;
          margin-right: 8px;
          text-transform: none;
        }
      }
    }
    .right_nft_inpts {
      flex-basis: 60%;
      margin-left: 30px;
      .inpts_nft_edit_right {
        display: flex;
        align-items: center;
        flex-direction: column;
        .MuiTextField-root {
          width: 100%;
          border: 1px solid #000000;
          border-radius: 6px;
          outline: none;
          box-shadow: none;
          margin-bottom: 28px;
          label {
            font-family: $f_primary !important;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #A0A0A0;
          }
          &:last-child{
            margin-bottom: 0;
          }
          input {
            box-shadow: none;
            outline: none;
            border: none;
            min-height: 49px;
            padding: 0 12px;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
.nft_two_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  button, a {
    padding: 17px 0;
    border-radius: 57.8371px;
    width: 100%;
    max-width: 247.55px;
    font-family:$f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
   transition: all 0.2s ease-out;
   margin-bottom: 18px;
  }
  .cncl_btn_nft {
    border: 1px solid #59D7FF;
    margin-right: 18px;
    color: #000000;
    &:hover {
      opacity: 0.8;
     }
  }
  .save_btn_nft {
    background: #59D7FF;
    color: #FFFFFF;
    &:hover {
      opacity: 0.8;
      background: #59D7FF;
     }
  }
}

.nft_ladt_input {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  .MuiTextField-root {
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    outline: none !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 1250px) {
  .nfts_main_two .inr_nft_body .right_nft_inpts {
    flex-basis: 60%;
    width: 58%;
  }
}
@media screen and (max-width: 1199px) {
  .nfts_main_two .inr_nft_body .right_nft_inpts {
    width: inherit;
  }
}
@media screen and (max-width: 991px) {
  .edit_nft_main .inr_nft_body .black_mmbr_bx,.edit_nft_main .inr_nft_body .right_nft_inpts {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .edit_nft_main .inr_nft_body {
    padding: 12px 11px 12px 12px;
  }
  .edit_nft_main .inr_nft_body .right_nft_inpts {
    margin-left: 14px;
  }
  .nft_two_btns button {
    padding: 12px 0;
    border-radius: 22.8371px;
    max-width: 100.55px;
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (max-width: 575px) {
  .edit_nft_main .inr_nft_body .black_mmbr_bx, .edit_nft_main .inr_nft_body .right_nft_inpts {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%; 
  }
  .edit_nft_main .inr_nft_body {
    flex-direction: column;
  }
  .edit_nft_main .inr_nft_body .black_mmbr_bx {
    padding: 40px 0 30px 0;
    margin-bottom: 20px;
  }
  .edit_nft_main .inr_nft_body .black_mmbr_bx h4 {
    margin: 60px 0 26px 0;
  }
  .nft_two_btns {
    margin-top: 30px;
  }
  .nft_two_btns .cncl_btn_nft {
    margin-right: 10px;
  }
  .nft_two_btns button {
    padding: 8px 0;
    max-width: 120.55px;
  }
}

// /jigscss//
.usr_list_header{
  border-bottom: none !important;
}
.tabs_widh_hith{
  min-height: 0px !important;
}
.tab_raight_btm_grop{
  display: flex;
  justify-content: space-between;
  // .btn_right {
  //   button {
  //     background: transparent;
  //     box-shadow: none;
  //   }
  // }
 .link_btn_two:hover {
  background-color: transparent !important;
 }
  .link_btn_two a{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #59D7FF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 35.8333px;
    max-width: 194px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF !important;
    padding: 9px 18px 9px 18px;
    margin-left: 5px;
    text-transform: capitalize;
    transition: all 0.3s ease-out;
    &:hover {
      opacity: 0.8;
    }
    img {
      margin-right: 8px;
    }
  }
  .tab_mriht{
    margin-right: 3px;
  }
}
.castm_tabs{
  .tabs_widh_hith {
    align-items: center;
    background: #EEEEEE;
    max-width: 465px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #C5C5C5;
    margin-left: 20px;
    margin-bottom: 18px;
  }
  .btn_grop{
    background: transparent;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    text-transform: capitalize;
    padding: 0px !important;
    border-right: 1px solid #C5C5C5;
    width: 100%;
    max-width: 154px;
    min-height: 34px;
  }
  .btn_grop:last-child{
    border-right: none !important;
  }
  .MuiTabs-indicator{
    display: none;
  }
}
.castm_tabs .btn_grop.Mui-selected {
  background: #59D7FF;
  color: #FFFFFF !important;
}
@media screen and (max-width:991px) {
  .castm_tabs .btn_grop{
    max-width: 130px;
  }
  .castm_tabs .tabs_widh_hith{
    max-width: 400px;

  }
}
@media screen and (max-width:767px) {
  .castm_tabs .btn_grop{
    max-width: 120px;
  }
  .castm_tabs .tabs_widh_hith{
    max-width: 363px;
    margin-left: 10px;
    margin-bottom: 13px;
  }
  .tab_raight_btm_grop .link_btn_two a{
    padding: 7px 9px 7px 9px;
    font-size: 14px;    
  }
  .tab_raight_btm_grop{
    flex-direction: column;
  }
  
}
@media screen and (max-width:575px) {
  .castm_tabs .btn_grop{
    max-width: 80px;
  }
  .castm_tabs .tabs_widh_hith{
    max-width: 363px;
  }
  .castm_tabs .btn_grop{
    min-height: 26px;
    font-size: 12px;
  }
}

.table_man_prnt{
  .table_man_prnt{
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0 15px;
  }
  .thed_prnt{
    tr{
      th{
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 10px;
        color: #000000;
      }
      .block_text{
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 13.7879px;
        line-height: 17px;
        text-align: center;
        color: #000000;
        padding-top: 10px;
      }
      span{
        margin-left: 45px;
      }
      
    }
  }
  .tbody_prnt{
    tr{
      td{
        font-family:$f_primary !important;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 17px;
        color: #000000;
        background: #EEEEEE;
        &:first-child {
          background: #EEEEEE;
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          background: #EEEEEE;
          border-radius: 0px 10px 10px 0px;
        }
      }
      .swich_btn_prnt{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
        width: 100%;
        border-radius: 35.8333px;
        max-width: 149px;
        height: 36px;
        margin: 0 auto;
        input {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
      }
      // .swich_box{
      //   .red_img_switch {
      //     filter: brightness(0) contrast(0) opacity(0.5);
      //     position: absolute;
      //     left: -20px;
      //     top: 8px;
      //   }
    
      //   .Mui-checked + .red_img_switch {
      //     filter: inherit;
      //   }
      //   .MuiSwitch-thumb {
      //     background: linear-gradient(270.07deg, #FF6262 -0.47%, #EC3030 97.06%) !important;
      //   }
      //   .MuiSwitch-root {
      //     .Mui-checked {
      //       .MuiSwitch-thumb {
      //         background: #59D7FF !important;
      //       }
      //     }
      //   }
      //   .MuiSwitch-track {
      //     background: #D7D7D7 !important;
      //     border-radius: 40px !important;
      //   }
      //   .MuiSwitch-root {
      //     width: 45px;
      //     height: 22px;
      //     padding: 0; 
      //     .MuiSwitch-switchBase {
      //       top: -6px;
      //       left: -5px;
      //     }
      //   }
      // }
      .link_box{
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          background: #FFFFFF;
          border: 1.40086px solid #DADADA;
          box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
          border-radius: 17.3197px;
          width: 48px;
          height: 48px;
          display: flex;
          padding: 10px;
          transition: all 0.3s ease-out;
          &:hover {
            background: #e8e6e6;
          }
        }
      }
    }
  }
}

.swich_btn_prnt {
  display: flex;
  align-items: center;
  .switch_me {
    display: flex;
    align-items: center;
  }
  img {
    filter: invert(0) brightness(0);
    opacity: 0.2;
  }
  .switch_bg {
    width: 45px;
    height: 22px;
    background: #D7D7D7;
    border-radius: 40px;
    position: relative;
    margin: 0 14px;
    .switch_span {
      background: linear-gradient(270.07deg, #FF6262 -0.47%, #EC3030 97.06%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      z-index: 9;
      position: absolute;
      top: 3px;
      left: 4px;
      transition: all 0.2s ease-out;
      pointer-events: none;
    }
  }
}
.swich_btn_prnt input:checked + .switch_me .switch_bg .switch_span {
  background: #59D7FF;
  left: 24px;
}
.swich_btn_prnt .switch_me .red_img_switch {
  filter: invert(0) brightness(1);
  opacity: 1;
}
.swich_btn_prnt input:checked + .switch_me .blue_img_switch{
  filter: invert(0) brightness(1);
  opacity: 1;
}
.swich_btn_prnt input:checked + .switch_me .red_img_switch{
  filter: invert(1) brightness(0);
  opacity: 0.2;
}
.top_p_main_rgt {
  background: #F8F8F8;
  border-radius: 6px;
  padding: 16px 20px 22px 16px;
  margin-bottom: 18px;
  p {
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7B7B7B;
  }
}


.mint_btn_bx {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 18px;
  .mint_btn {
    background: #59D7FF;
    border-radius: 57.8371px;
    width: 100%;
    height: 56.8px;
    max-width: 288px;
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    transition: all 0.3s ease-out;
    &:hover {
      background: #59D7FF;
      opacity: 0.8;
    }
  }
}
.nfts_main_two .black_mmbr_bx_tim_mmbr {
  padding: 150px 0 30px 0 !important;

  h4 {
    margin: 114px 0 36px 0 !important;
  }
}
.nfts_main_two .inr_nft_body .black_mmbr_bx {
  padding: 30px;
  .nft_ladt_input {
    background: #1D1D1D;
    border: 1px solid #59D7FF;
    border-radius: 6px;
    margin-bottom: 0;
    input {
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      @media screen and (max-width: 767px) {
        height: 14px;
      }
    }
    img {
      filter: brightness(100) opacity(1);
    }
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
    .MuiTextField-root{
      width: 100%;
      label{
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }
    } 
  }

}
.nfts_main_two .black_mmbr_bx {
  .blck_bg_ic {
    width: 64px;
  }
}
.nfts_main_two .inr_nft_body .black_mmbr_bx h4 {
  margin: 18px 0 44px 0;
}

@media screen and (max-width: 991px) {
  .nfts_main_two {
    .black_mmbr_bx , .right_nft_inpts {
      width: 50%;
    }
    .inr_nft_body .right_nft_inpts {
      margin-left: 10px;
      flex-basis: 50%;
    }
    .top_p_main_rgt {
      padding: 12px;
    }
    .top_p_main_rgt p {
      font-size: 14px;
      line-height: 22px;
    }
    .inr_nft_body {
      padding: 12px 14px 12px 12px;
    }
  }
}
@media screen and (max-width: 767px) {
  .nfts_main_two .inr_nft_body .black_mmbr_bx {
    padding: 60px 20px 30px 20px;
  }
  .nfts_main_two .inr_nft_body .black_mmbr_bx h4 {
    margin: 44px 0 36px 0;
  }
  .nfts_main_two .inr_nft_body {
    flex-direction: column;
  }
  .nfts_main_two .inr_nft_body .black_mmbr_bx, .nfts_main_two .inr_nft_body .right_nft_inpts {
    flex-basis: 100%;
    width: 100%;
  }
  .nfts_main_two .inr_nft_body .black_mmbr_bx {
    margin-bottom: 0;
  }
  .nfts_main_two .inr_nft_body .right_nft_inpts {
    margin-top: 22px;
  }
  .nfts_main_two .three_radio_prnt .radio_inr_nft .radio_bx_clik {
    min-width: 150px;
  }
}
@media screen and (max-width: 575px) { 
  .nfts_main_two .three_radio_prnt .radio_inr_nft .radio_bx_clik {
    min-width: 100px;
    margin-right: 5px;
    padding-right: 5px;
  }
  .nfts_main_two .three_radio_prnt .radio_inr_nft .radio_bx_clik label span {
    font-size: 11px;
    font-weight: 500;
  }
  .mint_btn_bx .mint_btn {
    width: 100%;
    height: 40.8px;
    font-size: 16px;
    line-height: 22px;
  }
  .nfts_main_two .inr_nft_body .right_nft_inpts .inpts_nft_edit_right .MuiTextField-root {
    margin-bottom: 10px;
  }
  .nfts_main_two .nft_ladt_input {
    margin-bottom: 10px;
  }
  .mint_btn_bx {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .nfts_main_two .inr_nft_body .black_mmbr_bx {
    padding: 20px 20px 20px 20px;
  }
  .nfts_main_two .inr_nft_body .black_mmbr_bx h4 {
    margin: 24px 0 16px 0;
  }
}
@media screen and (max-width: 420px) {
  .three_radio_prnt .radio_inr_nft {
    margin-bottom: 14px;
  }
  .three_radio_prnt .radio_inr_nft {
    flex-direction: column;
  }
  .three_radio_prnt {
    justify-content: center;
  }
  .nfts_main_two .three_radio_prnt .radio_inr_nft .radio_bx_clik {
    min-width: 100%;
    width: 100%;
    margin-bottom: 8px;
    height: 38px;
  }
  .three_radio_prnt {
    .MuiFormControl-root {
      width: 80%;
    }
  }
}

// mint_modal

.mint_modal_inr_main {
  max-width: 547px;
  margin: 200px auto;
  @media screen and (max-width: 767px) {
    margin: 80px auto; 
  }
  .mdl_hdr_usr {
    padding: 0;
    border: none;
    .back_btn_usr {
      left: 20px;
      top: 20px;
    }
  }
  .mint_mdl_in_tx_bx {
    padding-top: 66px;
    padding-bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      background: linear-gradient(273.39deg, #5FED6D 24.3%, #00B548 98.03%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    p {
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #282828;
      max-width: 320px;
      margin: 50px 0
    }
    button {
      background: #59D7FF;
      border-radius: 57.8371px;
      width: 100%;
      height: 56.8px;
      max-width: 288px;
      font-family: $f_primary !important;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      text-transform: inherit;
    }
  }
}

@media screen and (max-width: 575px) {
  .mint_modal_inr_main .mint_mdl_in_tx_bx {
    padding-bottom: 36px;
  }
  .mint_modal_inr_main .mint_mdl_in_tx_bx p {
    margin: 30px 0;
  }
}

// 11111111111111111

.drgs_drps_bx {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 385px;
  min-height: 100px;
  width: 100% !important;
  margin: 0 auto 70px auto;
  background: linear-gradient(273.39deg, rgb(138 248 173 / 30%) 24.3%, rgb(227 248 128 / 30%) 98.03%);
  border-radius: 20px;
}
.drgs_drps_bx:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -1px;
  border: 2px dashed #fff;
  background: linear-gradient(273.39deg, #00be3d 24.3%, #056c60 98.03%)
    border-box !important;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) !important;
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  border-radius: 20px;
  pointer-events: none;
}
.drgs_drps_bx h3 {
  font-family: $f_primary !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 23.9565px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  margin-bottom: 32px !important;
}
.drgs_drps_bx p {
  font-family: $f_primary !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  max-width: 180px;
  margin: 0 auto;
  padding-bottom: 36px;
}
.drgs_drps_bx aside {
  position: absolute;
  left: -50px;
  background: linear-gradient(273.39deg, #00be3d 24.3%, #056c60 98.03%)
    border-box !important;
  border-radius: 50%;
  box-shadow: 0px 0px 11.4451px #8af8ad;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
}
.drg_drop_box .drgs_drps_bx_nl {
  width: 100%;
  height: 234px;
  background: linear-gradient(273.39deg, #8af8ad1a 24.3%, rgb(227 248 128 / 10%) 98.03%) !important;
  border-radius: 14.6px;
  margin: 0 auto 36px auto;
  display: flex;
  align-items: flex-end;
  padding-bottom: 15px;
}
.drg_drop_box .drgs_drps_bx_nl::before  {
  border: 2px dashed #17241a;
  background: linear-gradient(273.39deg, rgba(138, 248, 173, 1) 24.3%, rgba(227, 248, 128, 1) 98.03%) !important;
  border-radius: 14.6px;
}

.drgs_drps_bx.drgs_drps_bx_nl {
  position: relative;
}
.drgs_drps_bx.drgs_drps_bx_nl aside {
  position: absolute;
  left: 0;
  background: transparent !important;
  border-radius: 14px;
  box-shadow: none;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.drgs_drps_bx.drgs_drps_bx_nl .thumb_bx {
  background: transparent;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  padding: 4px;
}
.drgs_drps_bx.drgs_drps_bx_nl .thumb_bx img[src^="blob:"]{
  object-fit: cover;
  width: 100% !important;
}
.drgs_drps_bx.drgs_drps_bx_nl .thumb_bx > div:first-child {
  background: transparent;
}
@media screen and (max-width: 575px) {
  .drgs_drps_bx {
    margin: 100px auto 30px auto;
  }
  .drgs_drps_bx aside {
    left: calc(50% - 50px);
    top: -80px;
  }
  .drgs_drps_bx h3 {
    font-size: 20.9565px !important;
    line-height: 20px !important;
    margin-bottom: 20px !important;
  }
}
.aplord_box_ab{
  .drgs_drps_bx {
    width: 281px !important;
    height: 234px !important;
    margin-bottom: 36px !important;
    background: rgba(25, 25, 25, 0.5);
    backdrop-filter: blur(25px);
    margin: 0;
    border-radius: 0;
  }
  .drgs_drps_bx:before{
    border-radius: 0;
    background: linear-gradient(273.39deg, #6e3ff2 24.3%, #056c60 98.03%) border-box !important;
  }
  .uplo_anth_img{
    font-family: $f_primary !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px;
    text-align: center;
    color: #59D7FF;
  }
  .drgs_drps_bx.drgs_drps_bx_nl .thumb_bx > div:first-child{
    height: 100%;
    width: 100%;
  }
}
.whit_colr{
  color: #fff !important;
  font-family: $f_primary !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
}
.img_chng_txt{
  margin-bottom: 70px;
  margin-top: 60px;
}
@media screen and (max-width: 991px) {
  .img_chng_txt {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 615px) {
  .aplord_box_ab .uplo_anth_img{
    top: 170px;
    right: 0;
    padding: 11px 14px;
    font-size: 12px;
  }
}
@media screen and (max-width: 575px) {
  .aplord_box_ab .uplo_anth_img {
    font-size: 10px !important;
  }
  .img_chng_txt {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .aplord_box_ab .drgs_drps_bx {
    width: 220px !important;
    height: 200px !important;
    margin-bottom: 20px !important;
  }
  .drgs_drps_bx p {
    padding-bottom: 16px;
  }
}
.fil_nil{
  .aplord_box_ab .drgs_drps_bx{
    border: 2px dashed #59D7FF !important;
    border-radius: 14.6px !important;
    background: rgb(89 215 255 / 10%);
  }
  .aplord_box_ab .drgs_drps_bx:before{
    position: inherit !important;
    display: none;
  }
  .aplord_box_ab .uplo_anth_img{
    position: inherit;
    background: transparent;
  }
}
// 111111111111111


.three_radio_prnt {
  display: flex;
  align-items: center;
  .radio_inr_nft {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .radio_bx_clik {
      background: #EAEAEA;
      border-radius: 6px;
      border: 1px solid transparent;
      min-width: 164px;
      padding-left: 0;
      height: 50px;
      display: flex;
      align-items: center;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      label {
        margin-left: 0;
        margin-right: 0;
        span {
          margin-right: 10px;
          font-family:$f_primary !important;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 10px;
          color: #000000;
          margin-right: 0;
        }
        span svg {
          color: #59D7FF !important;
          path {
            fill: #59D7FF;
          }
        }
      }
    }
  }
}

.three_radio_prnt {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .radio_me {
    
      display: flex;
      align-items: center;
      margin-right: 16px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
      label {
        font-family: $f_primary !important;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        color: #000000;
        position: relative;
        background: #EAEAEA;
        border-radius: 6px;
        min-width: 164px;
        padding: 14px 0 14px 48px;
        pointer-events: none;
        border: 1px solid transparent;
        span{
          &::before {
            content: "";
            background: #FFFFFF;
            border: 1px solid #59D7FF;
            width: 21px;
            height: 21px;
            position: absolute;
            left: 14px;
            top: 9px;
            opacity: 1;
            border-radius: 50%;
            transition: all 0.2s ease-out;
          }
          &::after {
            position: absolute;
            background: #59D7FF;
            content: "";
            width: 11px;
            height: 11px;
            left: 19px;
            top: 14px;
            opacity: 0;
            border-radius: 50%;
            transition: all 0.2s ease-out;
          }
        }
      }
      input:checked + label span {
        &::after {
          opacity: 1;
        }
      }
      input:checked + label {
        background: #F8F8F8 !important;
        border: 1px solid #59D7FF !important;
      }
  }
}
.unlesh_grin_prt_bx{
    background: #0D2F47;
    padding-top: 50px;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
  h3{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFA7C;    
    margin-bottom: 32px;
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    
    max-width: 854px;
    margin: 0 auto;
  }
}
.unlock_min_orng_bx{
  padding-top: 50px;
  padding-bottom: 65px;
  background: #FF9315;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    text-align: start;
    max-width: 665px;
    margin-left: 21px;
  }
  h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28.1714px;
    line-height: 36px;
    text-transform: uppercase;
    color: #000000;
  }
  span{
    color: #fff;
  }
}
.dare_part_wit_bg_box{
  background: #FFF;
  padding-top: 40px;
  padding-bottom: 56px;
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    span{color:#3DCDFB; }
  }
}
.p_rltv_bx{
  .buy_bitgrfi_P{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 46px;
    margin-bottom: 30px;
    span{
      font-weight: 700;
    }
  }
}
.click_red_mor_btn{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #FFFFFF;
  margin: 0 auto;
  display: flex;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  min-width: initial;
  height: auto;
  &:hover{
    color: #15D5FF;
  }
}
.ylo_p_flex{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  .ylo_ul_bx_v2{
    margin-right: 26px;
  }
}
.t_cent{
  text-align: center !important;
}
.jon_bit_gr_p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  color: #FFFFFF;
  max-width: 900px;
  margin: 0 auto !important;
  text-align: start;
  span{
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 26px !important;
  }
}
.for_mt12{
  margin-top: 15px !important;
}
.for_mt10{
  margin-bottom: 20px !important;
}
.ylo_ul_bx{
  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFA7C;
  }
}
.grin_brd_box{
  border: 1px solid #59D7FF;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.mt35_margi{
  margin-top: 35px !important;
}
.mt40_margi{
  margin-top: 40px !important;
}
.wi_on_ai_bx{
  background: #15D5FF;
  padding: 129px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .mt18_margi{
    margin-top: 20px;
  }
  p{
      font-weight: 300 !important;
      font-size: 16px !important;
      line-height: 26px;
      color: #000000;
    span{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #000000;
    }
  }
  img{
    height: 177.54px;
  }
}
.wi_ai_contin{
  max-width: 510px;
  margin-left: 85px;
}
.slids_futur_contin_bx{
  background: #F5F5F5;
  width: 100%;
}
.spasing_row .col-xl-3{
  margin-bottom: 20px;
}
@media screen and (max-width: 1250px) {
  .wi_on_ai_bx {
    padding: 79px 20px;
  }
  .three_radio_prnt .radio_me label {
    min-width: 144px;
  }
  .ylo_ul_bx p {
    font-size: 13px;
  }
}
@media screen and (max-width: 991px) {
  .wi_ai_contin {
    margin-left: 41px;
  }
  .wi_on_ai_bx {
    padding: 79px 20px;
    align-items: center;}
  .ylo_p_flex {
    flex-direction: column;
  }
  .p_rltv_bx .buy_bitgrfi_P{
    font-size: 22px;
    line-height: 35px;
  }
  .dare_part_wit_bg_box p {
    font-size: 26px;
    line-height: 39px;
  }
  .dare_part_wit_bg_box {
    padding-top: 30px;
    padding-bottom: 36px;
  }
  .unlock_min_orng_bx{
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 35px;
  }
  .unlock_min_orng_bx p{
    margin-left: 0;
    margin-top: 20px;
  }
  .unlesh_grin_prt_bx h3{
    font-size: 20px;
  }
  .nfts_main_two .inr_nft_body .black_mmbr_bx {
    flex-basis: 100%;
    width: 100%;
  }
  .nfts_main_two .inr_nft_body {
    flex-direction: column;
  }
  .nfts_main_two .inr_nft_body .right_nft_inpts {
    margin-left: 0;
    margin-top: 14px;
  }
  .three_radio_prnt .radio_me label {
    min-width: 164px;
  }
}
@media screen and (max-width: 767px) {
  .wi_on_ai_bx {
    padding: 50px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .wi_ai_contin {
    margin-left: 0;
    margin-top: 50px;
  }
  .mt40_margi {
    margin-top: 25px !important;
  }
  .jon_bit_gr_p span {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 26px !important;
  }
  .p_rltv_bx .buy_bitgrfi_P{
    margin-top: 35px;
    margin-bottom: 20px;
  }
  .dare_part_wit_bg_box p {
    font-size: 22px;
    line-height: 33px;
  }
  .dare_part_wit_bg_box {
    padding-top: 28px;
    padding-bottom: 32px;
  }
  .unlesh_grin_prt_bx h3, .unlesh_grin_prt_bx .h3 {
    font-size: 19px;
    margin-bottom: 23px;
  }
  .three_radio_prnt .radio_me label {
    min-width: 150px;
  } 
}
@media screen and (max-width: 575px) {
  .wi_on_ai_bx {
    padding: 30px 20px;
  }
  .p_rltv_bx .buy_bitgrfi_P {
    font-size: 18px;
    line-height: 31px;
  }
  .dare_part_wit_bg_box p {
    font-size: 20px;
    line-height: 30px;
  }
  .dare_part_wit_bg_box {
    padding-top: 24px;
    padding-bottom: 26px;
  }

  .three_radio_prnt .radio_me label {
    min-width: 124px;
  } 
  .three_radio_prnt .radio_me label span::before {
    left: 4px;
    top: 7px;
  }
  .three_radio_prnt .radio_me label span::after {
    left: 9px;
    top: 12px;
  }
  .three_radio_prnt .radio_me label {
    padding: 14px 0 14px 34px;
  }
  .three_radio_prnt .radio_me {
    margin-right: 10px;
  }
  .unlock_min_orng_bx h4 {
    font-size: 22.1714px;
    line-height: 28px;
  }
}
@media screen and (max-width: 480px) {
  .three_radio_prnt {
    flex-direction: column;
  }
  .three_radio_prnt .radio_me {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .three_radio_prnt .radio_me label {
    min-width: 164px;
  }
  .three_radio_prnt .radio_me label span::before {
    left: 10px;
    top: 7px;
  }
  .three_radio_prnt .radio_me label span::after {
    left: 15px;
    top: 12px;
  }
  .three_radio_prnt .radio_me label {
    padding: 14px 0 14px 44px;
  }
}

.view_work_min_prnt{
  max-width: 1432px;
  padding: 100px 15px 100px 15px;
  margin: 0 auto;
}
.click_vive_btn{
  margin: 0 auto;
  width: 241px;
  height: 58px;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #000000 !important;
  margin-top: 110px !important;
  border-radius: 0;
  border: none !important;
  outline: none !important;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background: #59D7FF !important;
  box-shadow: none !important;
}
.ourwork {
  position: relative;
}
.work_txt_bx{
  // background: red;
  width: 264px;
  height: 187px;
  padding-top: 60px;
  position: absolute;
  top: 310px;
  left: 44%;
  z-index: 99;
  transition: all 0.6s ease-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1475px) {
    top: 368px;
    left: 48%;
  }
  @media screen and (max-width: 1280px) {
    left: 47%;
  }
  @media screen and (max-width: 991px) {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    padding-top: 50px !important;
    left: 0 !important;
    top: 0 !important;
  }
  img{
    margin: 0 auto;
    display: flex;
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16.7075px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 16px;
  }
  span{
    margin-top: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.work_txt_bx{
  span, button{
    svg{
      margin-left: 10px;
    }
  }
}
.work_txt_bx_v2{
  position: initial !important;
  margin-bottom: 87px !important;
  margin: 0 auto;
  height: auto;
  button{
    margin-top: 45px !important;
  }
}
.view_work_pozi{
  position: relative;
  // background: rgb(0, 255, 234) !important;
  min-height: 775px;
  
  a{
    .work_img_sc01{
      position: absolute;
      top: 107px;
      left: 100px;
      width: 119px;
      height: 94px;
      object-fit: cover;
    }
    .work_img_sc02{
      position: absolute;
      top: 58px;
      left: 240px;
      width: 132px;
      height: 143px;
      object-fit: cover;
    }
    .work_img_sc03{
      position: absolute;
      top: 58px;
      left: 393px;
      width: 226px;
      height: 143px;
      object-fit: cover;
    }
    .work_img_sc04{
      position: absolute;  
      top:0px;  
      left: 640px;
      width: 182px;
      height: 202px;
      object-fit: cover;
    }
    .work_img_sc05{
      position: absolute;    
      left: 845px;
      top: 60px;
      width: 174px;
      height: 98px;
      object-fit: cover;
    }
    .work_img_sc06{
      position: absolute;    
      left: 1040px;
      top: -15px;
      width: 141px;
      height: 122px;
      object-fit: cover;
    }
    .work_img_sc07{
      position: absolute; 
      top: 222px;    
      left: 0px;
      width: 328px;
      height: 185px;
      object-fit: cover;
    }
    .work_img_sc08{
      position: absolute; 
      top: 222px;   
      left: 349px; 
      width: 177px;
      height: 317px;
      object-fit: cover;
    }
    .work_img_sc09{
      position: absolute; 
      top: 182px;
      left: 845px;

      width: 174px;
      height: 310px;
      object-fit: cover;
    }
    .work_img_sc10{
      top: 128px;
      left: 1040px;
      position: absolute;

      width: 242px;
      height: 199px;
      object-fit: cover;
    }
    .work_img_sc11{
      top: 128px;
      left: 1303px;
      position: absolute; 

      width: 113px;
      height: 199px;
      object-fit: cover;
    }
    .work_img_sc12{
      top: 350px;
      left: 1303px;
      position: absolute;  

      width: 113px;
      height: 144px;
      object-fit: cover;
    }
    .work_img_sc13{
      top: 428px;
      left: -18px;
      position: absolute; 

      width: 125px;
      height: 182px;
      object-fit: cover;
    }
    .work_img_sc14{
      top: 428px;
      left: 128px;
      position: absolute;

      width: 201px;
      height: 112px;
      object-fit: cover;
    }
    .work_img_sc15{
      top: 559px;
      left: 128px;
      position: absolute; 

      width: 131px;
      height: 182px;
      object-fit: cover;
    }
    .work_img_sc16{
      top: 559px;
      left: 279px;
      position: absolute; 

      width: 247px;
      height: 140px;
      object-fit: cover;
    }
    .work_img_sc17{
      width: 265px;
      height: 176px;
      top: 559px;
      left: 279px;
      position: absolute; 
    }
    .work_img_sc18{
      top: 430px;
      left: 547px;
      position: absolute; 

      width: 265px;
      height: 176px;
      object-fit: cover;
    }
    .work_img_sc19{
      top: 628px;
      left: 547px;
      position: absolute; 
      width: 94px;
      height: 94px;
      object-fit: cover;
    }
    .work_img_sc20{
    top: 628px;
      left: 660px;
      position: absolute; 

      width: 151px;
      height: 218px;
      object-fit: cover;
    }
    .work_img_sc21{
      top: 512px;
      left: 845px;
      position: absolute;

      width: 242px;
      height: 240px;
      object-fit: cover;
    }
    .work_img_sc22{
      top: 512px;
      left: 1107px;
      position: absolute; 

      width: 110px;
      height: 197px;
      object-fit: cover;
    }
    .work_img_sc23{
      top: 512px;
      left: 1238px;
      position: absolute; 

      width: 128px;
      height: 64px;
      object-fit: cover; 
    }
    .work_img_sc24{
      top: 348px;
      left: 1043px;
      position: absolute; 

      width: 213px;
      height: 139px;
      object-fit: cover;
    }
    .work_img_sc25{
      top: 348px;
      left: 1277px;
      position: absolute;  

      width: 142px;
      height: 103px;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 1475px) and (min-width: 1281px) {
  .view_work_min_prnt {
    padding-top: 114px;
  }
  .view_work_pozi a .work_img_sc01 {
    top: 107px;
    left: 40px;
  }
  .view_work_pozi a .work_img_sc02 {
    top: 58px;
    left: 180px;  }
  .view_work_pozi a .work_img_sc03 {
    left: 333px;  }
  .view_work_pozi a .work_img_sc04 {
    left: 581px; }
  .view_work_min_prnt {
    max-width: 1180px;
  }
  .view_work_pozi a .work_img_sc05 {
    left: 785px;
     top: 30px;
    width: 135px;
    height: 80px;  }
  .view_work_pozi a .work_img_sc06 {
    left: 784px;
    top: -111px;  }
  
  .view_work_pozi a .work_img_sc09 {
    width: 125px;
    top: 132px; }
  .view_work_pozi a .work_img_sc10 {
    top: 128px;
    left: 990px;
    width: 142px;
    height: 72px; }
  .view_work_pozi a .work_img_sc11 {
    top: -50px;
    left: 943px;
    width: 90px;  } 
  .view_work_pozi a .work_img_sc24 {
    top: 265px;
    left: 1020px;
    width: 141px;  }
  .view_work_pozi a .work_img_sc22 {
    top: 615px;
    left: 836px;
    width: 236px;
    height: 180px;  }
  .view_work_pozi a .work_img_sc21 {
    top: 400px;
    height: 193px;
    left: 837px;
    width: 255px; }
  .view_work_pozi a .work_img_sc23 {
    top: 322px;
    left: 997px;  }
  .view_work_pozi a .work_img_sc24 {
    top: 220px;
    left: 991px;
    width: 160px;
    height: 82px;  }
  .view_work_pozi a .work_img_sc25 {
    top: 28px;
    left: 1050px;
    width: 111px;  }
}
@media screen and (max-width: 1280px) and (min-width: 992px) {
  .view_work_pozi a .work_img_sc01 {
    top: 130px;
    height: 80px;
    left: 20px;
    width: 81px;
  }
  .view_work_pozi a .work_img_sc02 {
    top: 109px;
    height: 100px;
    left: 115px;
    width: 100px;
  }
  .view_work_pozi a .work_img_sc03 {
    left: 225px;
    top: 87px;
    height: 120px;
    width: 170px;
  }
  .view_work_pozi a .work_img_sc04 {
    left: 408px;
    top: 8px;
    width: 150px;
    height: 200px;
  }
  
  .view_work_pozi a .work_img_sc05 {
    left: 570px;
    top: 136px;
    width: 140px;
    height: 73px;
  }
  .view_work_pozi a .work_img_sc06 {
    left: 572px !important;
    top: -2px !important;
  }
  .view_work_min_prnt {
    max-width: 900px;
  }
  .view_work_pozi a .work_img_sc07 {
    position: absolute;
    top: 222px;
    height: 100px;
    width: 216px;
  }
  .view_work_pozi a .work_img_sc08 {
    top: 222px;
    left: 228px;
    height: 235px;
    width: 170px;
  }
  .view_work_pozi a .work_img_sc09 {
    width: 125px;
    top: 224px;
    left: 655px;
    height: 183px;
  }
  .view_work_pozi a .work_img_sc10 {
    top: 138px;
    left: 722px;
    width: 80px;
    height: 72px;
  }
  .view_work_pozi a .work_img_sc11 {
    top: -119px;
    left: 726px;
    width: 85px;
  }
  .view_work_pozi a .work_img_sc14 {
    top: 335px;
    left: 81px;
    width: 135px;
    height: 120px;
  }
  .view_work_pozi a .work_img_sc15 {
    top: 468px;
    left: 4px;
    width: 80px;
    height: 120px;
  }
  .view_work_pozi a .work_img_sc16 {
    top: 470px;
    left: 100px;
    height: 100px;
    width: 121px;
  }
  .view_work_pozi a .work_img_sc18 {
    top: 420px;
    left: 410px;
    height: 120px;
    width: 234px;
  }
  .view_work_pozi a .work_img_sc19 {
    top: 559px;
    left: 539px;
    position: absolute;
  }
  .view_work_pozi a .work_img_sc13 {
    top: 335px;
    left: -18px;
    height: 120px;
  }
  .view_work_pozi a .work_img_sc24 {
    top: 265px;
    left: 1020px;
    width: 141px;
  }
  .view_work_pozi a .work_img_sc06 {
    left: 784px;
    top: -111px;
  }
  .view_work_pozi a .work_img_sc22 {
    top: 615px;
    left: 836px;
    width: 236px;
    height: 180px;
  }
  .view_work_pozi a .work_img_sc20 {
    top: 468px;
    left: 240px;
  }
  .view_work_pozi a .work_img_sc21 {
    top: 418px;
    height: 153px;
    left: 656px;
    width: 215px;
  }
  .view_work_pozi a .work_img_sc22 {
    top: 560px;
    left: 412px;
    width: inherit;
    height: inherit;
    }
  .view_work_pozi a .work_img_sc23 {
    top: 225px;
    left: 790px;
    width: 96px;
    height: 96px;
  }
  .view_work_pozi a .work_img_sc24 {
    top: 44px;
    left: 725px;
    width: 140px;
    height: 82px;
  }
  .view_work_pozi a .work_img_sc25 {
    top: 138px;
    left: 813px;
    width: 73px;
    height: 71px;
  }
  .view_work_pozi .work_txt_bx {
    width: 230px;
    height: 187px;
    padding-top: 40px;
    position: absolute;
    top: 222px;
    left: 407px;
  }
}
@media screen and (max-width: 991px) {
  .view_work_min_prnt{
    padding-top: 15px;
  }
  .view_work_pozi .work_txt_bx{
    position: inherit !important;
    background-color: rebeccapurple;
    margin-bottom: 20px;
    width: 100%;
  }
  .view_work_pozi{
    display: block;
    position: inherit !important;
    a{
      margin-bottom: 15px !important;
      margin-right: 15px !important;
      display: inline-block;
      img{
        // vertical-align: baseline !important;
        display: inline-block;
        position: inherit !important;
      }
    }
  }
  .view_work_pozi a .work_img_sc01 {
    height: 70px !important;
  }
  .view_work_pozi a .work_img_sc02 {
    height: 90px !important;
  }
  .view_work_pozi a .work_img_sc03 {
    height: 90px !important;
  }
  .view_work_pozi a .work_img_sc04 {
    height: 140px !important;
  }
  .view_work_pozi a .work_img_sc05 {
    height: 60px !important;
  }
  .view_work_pozi a .work_img_sc06 {
    height: 90px !important;
  }
  .view_work_pozi a .work_img_sc07 {
    height: 110px !important;
  }
  .view_work_pozi a .work_img_sc08 {    
    height: 170px !important;
  }
  .view_work_pozi a .work_img_sc09 {
    height: 170px !important;
  }
  .view_work_pozi a .work_img_sc10 {
    height: 110px;
  }
  .view_work_pozi a .work_img_sc012 {    
    // height: 110px !important;
  }
  .view_work_pozi a .work_img_sc013 {    
    height: 110px !important;
  }
  .view_work_pozi a .work_img_sc014 {    
    height: 110px !important;
  }
  .view_work_pozi a .work_img_sc16{
    height: 110px !important;
  }
  .view_work_pozi a .work_img_sc18{
      height: 121px !important;
  }
  .view_work_pozi a .work_img_sc20{
    height: 120px !important;
  }
  .view_work_pozi a .work_img_sc21{
    height: 120px !important;
  }
  .view_work_pozi a .work_img_sc22{
    height: 120px !important;
  }
  .view_work_pozi a .work_img_sc24{
    height: 120px !important;
  }
  .view_work_pozi a .work_img_sc25 {
    height: 60px;
  }
}
@media screen and (max-width: 1475px){
  .view_work_min_prnt{
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 991px){
  .view_work_min_prnt{
    margin-top: 0px !important;

  }
  .view_work_pozi .work_txt_bx {
    position: inherit !important;
    background-color: transparent !important;
    margin-bottom: 20px;
    width: 100%;
    height: 140px;
    padding-top: 20px;
}
}
@media screen and (max-width: 767px){
  .click_vive_btn{
    margin-top: 90px !important;
    width: 221px;
    height: 48px;
    font-size: 16px;
  }
}
.gallyr_min_prnt_bx{
  .cstmGallr{
    // max-width: 1595px;
    padding-left: 15px;
    padding-right: 15px;
    // margin: 0 auto 80px auto;
    max-height: 800px;
    overflow-y: auto;
    @media screen and (max-width: 991px) {
      max-height: 500px;
    }
  }
  &.open {
    .cstmGallr{
      max-height: inherit;
      overflow-y: initial;
    }
  }
}
.walcom_bitgra_top_contin{
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 250;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    border-bottom: 1px solid #DADADA;
    span{
      font-weight: 700;
    }
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.335em;
    color: #000000;
    margin-top: 10px;
  }
}
.bit_tab_min_prnt{
  margin-top: 20px;
}
.tab_had_bx{
  margin-bottom: 30px;
  .MuiTabs-indicator{
    display: none;
  }
  .MuiTabs-flexContainer{
    justify-content: center;
    align-items: center;
    min-height: 65px;
  }
  button{
    border: none !important;
    outline: none !important;
    background: rgba(202, 202, 202, 0.4);
    backdrop-filter: blur(2.25713px);
    border-radius: 37.6188px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000000 !important;
    position: relative;
    min-height: 38px;
    text-transform: inherit;
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 1px;
      background: url(../img/tab_btn_img_b.svg) no-repeat center;
      height: 37px;
      width: 37px;
      border-radius: 50%;
    }
  }
  .tab_orgin_btn{
    padding-left: 48px;
    margin-right: 15px;
  }
  .tab_coll_btn{
    padding-left: 48px;
  }
  .tab_orgin_btn.Mui-selected {
    box-shadow: 0px 0px 10px rgba(89, 215, 255, 0.71);
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 1px;
      background: url(../img/tab_btn_img_b_orignl.png) no-repeat center;
      height: 37px;
      width: 37px;
      border-radius: 50%;
    }
  }
  .tab_coll_btn.Mui-selected{
    box-shadow: 0px 0px 10px rgba(255, 179, 89, 0.71);
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 1px;
      background: url(../img/cloo_b_tba_ic.png) no-repeat center;
      height: 37px;
      width: 37px;
      border-radius: 50%;
    }
  }
}
.tab_panl_bx_min{
  display: flex;
  justify-content: center;
  // background: linear-gradient(157.28deg, #142E4C 2.11%, #8E9CC8 99.08%);
  padding: 0 !important;
  
  >p{
    max-width: 100%;
    width: 100%;
  }
}
.tab_sid_contin{
  h3{
    padding: 0;
    width: auto;
    max-width: auto;
    background: none;
    margin: 0;
    margin-bottom: 0 !important;
    color: #000;
  }
  .def_bl_ttl .cmng_sn_txt{
    color: #59D7FF;
    right: -49px;
  }
}
.def_conta_iner{
  max-width: 1250px !important;
}

.tab_two_select{
  position: relative;
  z-index: 2;
  .MuiInputBase-formControl{
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-radius: 60px;
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
  }  
}
.sel_bx_in{
  .MuiList-padding.MuiMenu-list{
    .Mui-selected:hover {
      background-color: rgba(210, 25, 25, 0.08) !important;
    }
   }
  .MuiSelect-outlined{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    margin-top: 6px;
  }
}
#menu-{
  .MuiPaper-elevation.MuiPaper-rounded{
    background: rgba(255, 255, 255, 0.81) !important; 
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.34) !important;
    backdrop-filter: blur(2px) !important;
    border-radius: 10px !important;
  }
  ul{
    li{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;;
      background: #ffffff00 !important;
    }
  }
}
.totel_img_box_v2a{
  img{
    position: relative;
    left: -14px; 
  }
}
.synerg_box{
  h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
  }
}
.synergy_bx_row{
  margin-top: 45px;
}
.synergy_collection_min{
  background: #142E4D;
  width: 100%;
  padding-top: 65px;
  padding-bottom: 30px;
  margin-top: 38px;
  padding-left: 15px;
  padding-right: 15px;
  h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 28px;
    text-align: center;
  }
  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    max-width: 1100px;
    text-align: left;
    margin: 0 auto;
    span{
      font-weight: 700;
    }
  }
  h4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
    span{
      color: #BAFFFF;
      font-weight: 700;
    }
  }
}

.syne_contin{
  max-width: 1200px;
  padding: 0 10px;
  width: 100%;
}
.tab_siad_select{
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  padding: 10px;
  position: relative;
  top: 75px;
}
.synergy_colle_forbg__color{
  background: #142E4D;
  padding-bottom: 40px;
  .grin_brd_box{
    max-width: 1200px;
    margin: 0 auto;
  }
  .ylo_ul_bx p{
    color: #BAFFFF;
  }
  .read_more_box_min{
    padding-left: 15px;
    padding-right: 15px;
  }
}
.def_conta_iner_ried_mor{
  padding-top: 30px;
}
.def_conta_iner_ried_mor{
  .vew_aro_btn{
    margin-top: 90px;
    margin-bottom: 30px;
  }
}
.recent_add_bax{
  background: #E5E5E5;
  padding-top: 55px;  
  padding-bottom: 135px;
  .recent_add_p{
    margin-bottom: 35px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
  }
}
.TabPanelone_bx{
  background: linear-gradient(157.28deg, #142E4C 2.11%, #8E9CC8 99.08%);
  padding: 1px;
}
.TabPanelTwo_bx{
  background: linear-gradient(157.28deg, #A47346 2.11%, #DFD19E 99.08%);
  padding: 1px;
}
.contin_tab_two{
  .man_box_colps .bit_col_p{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .synerg_box{
    h2{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
      color: #FFFFFF;
      span{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
.vew_aro_btn_v2{
  background: #FFC659 !important;
  margin-top: 100px !important;
  margin-bottom: 0 !important;
  &:hover{
    color: #a77f32 !important;
  }
}
.selct_bx{
  overflow: inherit;
  .react-select__control{
    width: 152px !important;
    height: 38px !important;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 60px;
  }
  .react-select__indicator-separator{
    display: none;
  }
  .react-select__menu-list{
    background: rgba(255, 255, 255, 0.81);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.34);
    backdrop-filter: blur(2px);
    border-radius: 10px;
  }
  .react-select__option--is-selected{
    // tru_ic
    background:#FFF url(../img/tru_ic.svg) no-repeat 90% 15px !important;
    color: #000;
  }
  .react-select__input{
    font-family: 'Poppins' !important; 
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #000000 !important;
    &::placeholder{
      font-family: 'Poppins' !important; 
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #000000 !important;
    }
  }
  .react-select__option{
    font-family: 'Poppins' !important; 
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #000000 !important;
    background: transparent !important;
  }
  .react-select__menu{
    background: transparent !important;
  }
}
.top_selact_fx{
  display: flex;
  .selct_bx_v2a{
    margin-left: 10px;
  }
}
.bit_io_img{
  position: absolute;
  right: 0;
}
.welcom_whe_txt{
  margin: 0 auto;
  h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 250;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  
}
.cntnt_crtn_frm_for_new_se{
  border-radius: 0;
  padding: 68px 30px 30px 30px !important;
  margin-top: 0;
  .crtn_frm_form{
    padding: 0;
    border: none;
  }
  .crtn_frm_ttl{
    margin: 0 auto 52px auto;
  }
  .crtn_frm_ttl h6{
    margin-bottom: 25px;
  }
  .crtn_frm_form .cntct_btn{
    margin-top: 15px;
    width: 259.57px !important;
    color: #000 !important;
    background: #59D7FF !important;
    &:hover{
    background: #0989b0 !important;
    color: #ffffff !important;
    }
  }
  .crtn_frm_form .form-control{
    border: 1px solid #9fa4b4;
    border-radius: 0px !important;
    color: #000000 !important;
    background: white !important;
  }
  .tex_high .form-control{
    height: 120px;
  }
}

@media screen and (max-width: 1150px){
  .def_bl_ttl {
    font-size: 15px !important;
  }
  .tab_sid_contin .def_bl_ttl .cmng_sn_txt {
    color: #59D7FF;
    right: -12px;
    font-size: 14px;
  }
  .tab_siad_select {
    top: -15px;
  }

}
@media screen and (max-width: 991px){
  .cntnt_crtn_frm_for_new_se{

  }
  .about_contin_box .bit_about_cap_ic {
    top: -40px !important;
  }
  .tab_panl_bx_min{
    padding-top: 0 !important;
  }
  .walcom_bitgra_top_contin h2, .walcom_bitgra_top_contin .h2 {
    font-size: 27px;
  }
  .walcom_bitgra_top_contin p {
    font-size: 11px;
    margin-top: 7px;
  }
  .tab_siad_select {
    top: -15px;
  }
  .synergy_collection_min h4, .synergy_collection_min .h4 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px){
  .tab_sid_contin{
    margin-bottom: 20px;
  }
  .synergy_collection_min h4, .synergy_collection_min .h4 {
    font-size: 22px;
    line-height: 36px;
  }
  .synergy_collection_min {
    padding-top: 30px;
    padding-bottom: 18px;
    margin-top: 10px;
  }
  .synerg_box p{
    font-size: 15px;
  }
  .walcom_bitgra_top_contin h2, .walcom_bitgra_top_contin .h2 {
    font-size: 24px;
  }
  .tab_siad_select {
    top: -15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 575px){
  .cntnt_crtn_frm_for_new_se {
    padding: 45px 10px 20px 10px !important
  }
  .about_contin_box .bit_about_rit_b {
    height: 130px;
  }
  .about_contin_box .bit_about_cap_ic {
    top: -20px !important;
    height: 210px;
  }
  .walcom_bitgra_top_contin {
    min-height: 302px;
  }
  .walcom_bitgra_top_contin h2, .walcom_bitgra_top_contin .h2 {
    font-size: 15px;
  }
  .walcom_bitgra_top_contin p {
    font-size: 11px;
    line-height: 22px;
    margin-top: 3px;
  }
  .tab_had_bx .MuiTabs-flexContainer {
    flex-direction: column;
    min-height: 120px;
  }
  .tab_had_bx .tab_orgin_btn{
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .synergy_collection_min h2, .synergy_collection_min .h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .synergy_collection_min h4{
    font-size: 19px;
    line-height: 30px;
    margin-top: 24px;
  }
}
.abut_se_top_img{
  min-height: 335px;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: 767px) {
    min-height: 235px;
  }
}
.about_contin_box{
  position: relative;
  .bit_about_cap_ic{
    position: absolute;
    left: 0;
    top: -80px;
    pointer-events: none;
  }
  .bit_about_rit_b{
    position: absolute;
    right: 0;
    bottom: -6px;
    height: 410px;
    pointer-events: none;
  }
}
.about_contin_box{
 margin-top: 85px;
 padding-bottom: 140px;
  .walcom_bitgra_top_contin_se_2{
    min-height: inherit;
    margin-top: 80px;
    margin-bottom: 140px;
    max-width: 985px;
    padding: 0 15px;
    margin: 0 auto;
    h2{
      border: none;
    }
    p{
      margin-top: 0;
    }
  }
}
.abou_p{
  max-width: 985px;
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 40px;
  p{
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
  }
}
@media screen and (max-width: 1500px){
  .about_contin_box .bit_about_rit_b {
    height: 290px;
  }
  .about_contin_box .bit_about_cap_ic {
    height: 370px;
  }
}
@media screen and (max-width: 1100px){
  .about_contin_box .bit_about_rit_b {
    height: 220px;
  }
  .about_contin_box .bit_about_cap_ic {
    height: 320px;
  }
}
@media screen and (max-width: 991px){
  .about_contin_box {
    margin-top: 40px;
    padding-bottom: 80px;
  }
  .abou_p p {
    font-size: 15px;
  }
}
@media screen and (max-width: 575px){
  .abou_p p {
    font-size: 13px;
  }
  .about_contin_box {
    margin-top: 20px;
    padding-bottom: 50px;
  }
}
.mainGallerybx {
  position: relative;
}
.absltGallery {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  opacity: 1;
  transition: all 0.8s ease-out;
  transform: translateZ(0px);
  transition-property: opacity,transform;
  transform: translate3d(0,0px,0);
  pointer-events: visible;
}
.gallyr_min_prnt_bx {
  position: relative;
  height: 1130px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.8s ease-out;
  transform: translateZ(0px);
  transition-property: opacity,transform;
  transform: translate3d(0,70px,0);
  pointer-events: none;
  padding-top: 200px;
  @media screen and (max-width: 991px) {
    height: 1230px;
    padding-top: 60px;
  }
  @media screen and (max-width: 767px) {
    height: 1630px;
  }
  @media screen and (max-width: 600px) {
    height: 1830px;
  }
  @media screen and (max-width: 555px) {
    height: 2130px;
  }
  @media screen and (max-width: 520px) {
    height: 2430px;
  }
  @media screen and (max-width: 420px) {
    height: 2630px;
  }
  @media screen and (max-width: 410px) {
    height: 2830px;
  }
  @media screen and (max-width: 375px) {
    height: 2930px;
  }
  @media screen and (max-width: 360px) {
    height: 3030px;
  }
}
.bttmActionbx {margin-bottom: 50px;}
.mainGallerybx.open {
  .gallyr_min_prnt_bx {
    transform: translateZ(0px);
    opacity: 1;
    pointer-events: visible;
    height: auto;
  }
  .absltGallery {
    opacity: 0;
    transform: translate3d(0,100px,0);
    pointer-events: none;
  }
  
}
.absltBtmAction {
  position: absolute;
  bottom: 40px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.6s ease-out;
  z-index: 99;
  padding-right: 0px;
}
.ourwork.open {
  .work_txt_bx {
    top: -5px;
    left: 0 !important;
    width: 100% !important;
    span {
      opacity: 0;
      pointer-events: none;
    }
    @media screen and (max-width: 1475px) {
      left: calc(50% - 127px);
    }
  }
  .absltBtmAction {
    bottom: 78%;
    padding-right: 0px;
    padding-right: 0;
    padding-left: 4px !important;
    @media screen and (max-width: 1475px) {
      padding-right: 0px;
      padding-left: 20px;
    }
    @media screen and (max-width: 991px) {
      bottom: 99%;
    }
    @media screen and (max-width: 575px) {
      padding-left: 0px !important;
    }
  }
}
.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee-animation 20s linear infinite;
  // background: #EFFEFF; 

  @keyframes marquee-animation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}


.image-container {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.image-container.fade-out {
  opacity: 0;
}
// .image-container {
//   display: flex;
//   flex-wrap: wrap;
// }

// .image-container img {
//   opacity: 0;
//   transition: opacity 0.5s ease-in-out;
// }

// .image-container img.loaded {
//   opacity: 1;
// }
.slid_prnt_bx{
  .slick-disabled{
    display: none;
  }
  .slick-next {
    right: calc(40% - 17px);
    top: auto !important;
    bottom: -100px;
    background: url(../img/sldr_r_arrow.svg) no-repeat 8px 4px;
    z-index: 2;
    height: 35px;
    width: 35px;
    &::before{
      display: none;
    }
  }
  .slick-prev{
    z-index: 2;
    left: calc(40% - 17px);
    top: auto !important;
    bottom: -100px;
    right: 555px;
    background: url(../img/sldr_l_arrow.svg) no-repeat 8px 4px;  
    height: 35px;
    width: 35px;
    &::before{
      display: none;
    }
  }
  .slick-dots{
    bottom: -80px;
    li{
      margin: 0 15px;
      button{
        background: #A9A9A9;
        height: 9px;
        width: 9px;
        border-radius: 50%;
        &::before{
          display: none !important;
        }
      }
    }
    .slick-active{
      button{
        width: 39px;
        height: 9px;
        background: #000000;
        border-radius: 6px;
      }
    }
  }
}
.prtnr_prjtct_main_bx{  
  .sldr_box{
    .sldr_box_top{
      background: transparent;
      box-shadow: none;
      }
    border: none;
    position: relative;
    background: transparent;
    &::before{
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background: linear-gradient(346deg, rgba(0, 0, 0, 0) 0%, #4e4141 50.16%, rgba(0, 0, 0, 0) 97.28%);
      opacity: 0.6;
    }
  }
  .slick-slide{
    &:first-child{
      .sldr_box{
        &::before{
          display: none;
        }
      }
    }
  }
}
.jasty_btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .main_hdng{
    margin-bottom: 0;
  }
}
.vive_all_link{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
  display: flex;
  align-items: center;
  .for_arwo_bx{
    margin-left: 14px;
    width: 58px;
    height: 58px;
    background: #59D7FF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bit_resevb_p{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.6;
  margin-top: 65px !important;
  text-align: center;
}
.footear_lin_gri_min{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .footear_lin_bx{
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      font-family: 'Poppins';
      font-style: normal;
      text-align: start;
      color: #FFFFFF !important;
      opacity: 1;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
    }
    a{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 17.0049px;
      line-height: 20px;
      text-decoration-line: underline;
      color: #59D7FF;
      border: none;
      justify-content: start;
      margin-top: 7px !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
      &:hover{
        color: #fff;
      }
      img{
        margin-right: 8px;  
      }
      svg{
        margin-left: 3px;
      }
    }
  }
}
.slid_prnt_bx{
  margin-bottom: 90px;
}
@media screen and (max-width: 1430px){
  .slid_prnt_bx{
    margin-bottom: 160px;
  }
}
@media screen and (max-width: 1400px){
  .footear_lin_gri_min .footear_lin_bx{
    width: 100%;
  }
  .for_p_0{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
  }
  .ftrlg_bx {
    padding-top: 20px;
  }
}
@media screen and (max-width: 1200px){
  .slid_prnt_bx .slick-dots {
    bottom: -76px;
  }
  .slid_prnt_bx .slick-prev {
    left: calc(35% - 17px);
    top: calc(119% - 17px);
  }
  .slid_prnt_bx .slick-next{
    right: calc(35% - 17px);
    top: calc(119% - 17px);
  }
}
@media screen and (max-width: 1020px){
  .slid_prnt_bx .slick-next, .slid_prnt_bx .slick-prev {
    bottom: -95px;
  }
}
@media screen and (max-width: 991px){
  .slid_prnt_bx{
    margin-bottom: 110px;
  }
  .slid_prnt_bx .slick-prev {
    left: calc(30% - 17px);
    top: calc(121% - 17px);
  }
  .slid_prnt_bx .slick-next{
    right: calc(30% - 17px);
    top: calc(121% - 17px);
  }
}
@media screen and (max-width: 700px){
  .slid_prnt_bx .slick-dots {
    display: none !important;
  }
  .vive_all_link{
    margin-top: 20px !important;
  }
  .jasty_btn {
    align-items: self-start;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-direction: column;
    .jasty_btn .vive_all_link {
      margin-top: 20px;
      font-size: 16px;
    }
  }
  .vive_all_link .for_arwo_bx {
    margin-left: 14px;
    width: 48px;
    height: 48px;
  }
 
}
@media screen and (max-width: 575px){
  .footear_lin_gri_min{
    flex-direction: column;
  }

}
.marquee_bg_color{
  background: #EFFEFF;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
  color: gray;
}

.cropped-image {
  height: 600px;
  width: auto;
}

.cropped-image-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

._coverImage-holder  {
  padding: 25px 40px;
  background-color:black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}


.container {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top: 25px; */
}

.crop-container {
  height: 300px;
  width: 600px;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 600px;
  position: absolute;
  bottom: -15px;
}

.drop-zone{
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cropImageModal {
  max-width: 640px;
  background: rgba(13, 47, 71, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 0;
  .usr_mdl_body_prnt {
    padding: 10px 30px;
    .controls {
      width: calc(100% - 15px);
      bottom: 0px;
      padding: 0 15px;
      label {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
      }
      .MuiSlider-colorPrimary {
        color: #59D7FF;
        .MuiSlider-rail {
          background-color: #59D7FF;
          opacity: 1;
        }
        .MuiSlider-thumb {
          background-color: #FFFFFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
  .user_mdl_ftr {
    border: none;
  }
  .drop-zone {
    background: rgba(255, 147, 21, 0.1);
    border: 3px dashed #FF9315;
    margin-bottom: 15px;
    height: 362px;
    div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: #FF9315;
      &::before {
        content: "";
        background: url(../img/upload-filled.svg) no-repeat 0 0;
        height: 102px;
        width: 102px;
      }
    }
  }
  .user_mdl_ftr {
    padding-bottom: 25px;
    .create_btn_usr {
      border-radius: 0;
      opacity: 1;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #000000 !important;
    }
  }
}
.cropHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
  }
  button.back_btn_usr {
    position: relative;
    left: 0;
    min-width: inherit;
    padding: 0;
    svg {
      fill: #59D7FF;
    }
  }
}
@media screen and (max-width: 767px) {
  .cropImageModal .usr_mdl_body_prnt {
    padding: 10px 15px;
  }
  .crop-container {
    width: 100% !important;
  }
  .cropImageModal .drop-zone {
    height: 262px;
  }
}
.cssnText {
  font-family: $f_primary;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  padding-top: 2px;
  padding-left: 23px;
  margin-bottom: -13px;
}
.btnpNone {pointer-events: none;}
.cmmssnbtntxt {
  display: block;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: -10px;
}
.for_centar_flx{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 40px;
}
.devlig_img_a{
  margin-right: 120px;
  &:last-child{
    margin-right: 0px;
  }
  img{
    height: 55px;
    width: 55px;
    border: 1px solid #424242;
    padding: 10px;
  } 
}
.devlig_img_a_p{
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  min-width: 135px;
}
@media screen and (max-width: 991px){
  .devlig_img_a {
    margin-right: 30px;
  }
  .footear_lin_gri_min .footear_lin_bx p{
    font-size: 17px;
  }
  .footear_lin_gri_min .footear_lin_bx a{
    font-size: 16px !important;
  }
  .footear_lin_gri_min {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px){
  .footear_lin_gri_min{
    flex-direction: column;
  }
  .devlig_img_a{
    margin-bottom: 30px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    &:last-child{
    margin-left: 0 !important;
    margin-right: 0 !important;
    }
  }
  .bit_resevb_p {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 575px){
  .devlig_img_a {
    margin-bottom: 20px;
  }
  .bit_resevb_p {
    margin-top: 20px !important;
  }
  .footear_lin_gri_min {
    margin-top: 20px;
  }
  .footer_logo{
    width: 160px;
  }
  .ftrlg_bx p {
    font-size: 13px;
    line-height: 15px;
  }
  .footear_lin_gri_min .footear_lin_bx p{
    font-size: 15px;
  }
  .footear_lin_gri_min .footear_lin_bx a{
    font-size: 15px !important;
  }
}