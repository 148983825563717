.login-form,
.admin {
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 250px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input {
      padding: 5px;
      width: 100%;
      margin-bottom: 5px;
      border: none;
      background-color: #f0f0f0;
    }

    .button-container {
      margin-top: 15px;
    }

    button {
      padding: 5px;
      cursor: pointer;
      width: 100%;
      border: none;
      color: white;
      font-weight: bold;
      background-color: #59d7ff;
    }
  }
}

.admin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .cloudinary-button-container {
    button {
      background-color: #59d7ff !important;
      border: none !important;
      font-size: 1.4rem !important;
      padding: 5px 15px;
      cursor: pointer;
    }
  }

  .log-out {
    margin-top: 50px;
    border: none;
    padding: 5px 15px;
  }
}

.upload-form {
  img {
    width: 250px;
    height: auto;
    display: block;
  }

  .submit-button {
    font-family: inherit;
    background-color: #59d7ff;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }

  .cancel-button {
    font-family: inherit;
    background-color: #d04141;
    color: white;
    cursor: pointer;
  }

  input,
  .form-input {
    width: 250px;
    margin-bottom: 30px;
  }

  label {
    margin-top: 20px;
    display: block;
  }
}

.edit-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  button {
    color: white;
    border: none !important;
    font-size: 1.4rem !important;
    padding: 5px 15px;
    cursor: pointer;
  }

  .button-red {
    background-color: #d56161 !important;
  }

  .button-green {
    background-color: green;
  }
}

.home-gallery {
  position: relative;
  // overflow-x: scroll;
  margin: 0 auto 40px auto;
  // width: 90%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  // justify-content: flex-start;
  max-width: 1565px;
@media screen and (max-width: 991px) {
  width: 100%;
}
  .gallery-set {
    // width: 1000px;
    display: grid;
    grid-template-columns: repeat(1542, 1px);
    grid-template-rows: repeat(645, 1px);
    // grid-template-rows: repeat(5, 94px);
    // grid-template-rows: auto;
    // grid-template-areas: 
    // "a b c d e f g h i";
    // grid-template-areas:
    //   "a a a b b c c c h h h j j j j j o o o o o o r r r r x x"
    //   "a a a b b c c c h h h j j j j j o o o o o o r r r r x x"
    //   "d d d d d d d d h h h j j j j j o o o o o o s s s y y y"
    //   "d d d d d d d d h h h k k k l l o o o o o o s s s y y y"
    //   "d d d d d d d d h h h k k k l l o o o o o o t t t y y y"
    //   "e e e e f f f f h h h m m m m m p p p p u u t t t y y y"
    //   "e e e e f f f f h h h m m m m m p p p p u u t t t y y y"
    //   "e e e e f f f f h h h m m m m m p p p p v v v w w y y y"
    //   "e e e e g g g i i i i i n n n n p p p p v v v w w y y y"
    //   "e e e e g g g i i i i i n n n n q q q q v v v w w y y y"
    //   "e e e e g g g i i i i i n n n n q q q q v v v w w z z z";
  }

  // .gallery-set:last-of-type {
  //   margin-left: -100px;
  // }

  .gallery-block {
    border: 1px solid white;
  }

  .gallery-item {
    position: relative;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .block-a {
    grid-area: a;
    grid-column: 1; /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:144px;
    height:94px;
  }
  .block-b {
    grid-area: b;
    grid-column: 145 + (5*1); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:94px;
    height:94px;
  }
  .block-c {
    grid-area: c;
    grid-column: 238 + (5*2); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:119px;
    height:94px;
  }
  .block-d {
    grid-area: d;
    grid-column: 357 + (5*3); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:174px;
    height:308px;
  }
  .block-e {
    grid-area: e;
    grid-column: 531 + (5*4); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:250px;
    height:138px;
  }
  .block-f {
    grid-area: f;
    grid-column: 781 + (5*5); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:127px;
    height:138px;
  }
  .block-g {
    grid-area: g;
    grid-column: 908 + (5*6); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:182px;
    height:200px;
  }
  .block-h {
    grid-area: h;
    grid-column: 1090 + (5*7); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:174px;
    height:308px;
  }
  .block-i {
    grid-area: i;
    grid-column: 1264 + (5*8); /* Menempatkan block-a di kolom 1 */
    grid-row: 1; /* Menempatkan block-a di baris 1 */
    width:253px;
    height:185px;
  }
  .block-j {
    grid-area: j;
    grid-column: 1; /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + (6*1); /* Menempatkan block-a di baris 1 */
    width:369px;
    height:208px;
  }
  .block-k {
    grid-area: k;
    grid-column: 369 + 174 + (5*2); /* Menempatkan block-a di kolom 1 */
    grid-row: 139 + (6*1); /* Menempatkan block-a di baris 1 */
    width:129px;
    height:94px;
  }
  .block-l {
    grid-area: l;
    grid-column: 369 + 174 + 129 + (5*3); /* Menempatkan block-a di kolom 1 */
    grid-row: 139 + (6*1); /* Menempatkan block-a di baris 1 */
    width:248px;
    height:164px;
  }
  .block-m {
    grid-area: m;
    grid-column: 369 + 174 + 129 + 248 + (5*4); /* Menempatkan block-a di kolom 1 */
    grid-row: 200 + (6*1); /* Menempatkan block-a di baris 1 */
    width:182px;
    height:101px;
  }
  .block-n {
    grid-area: n;
    grid-column: 1264 + (5*8); /* Menempatkan block-a di kolom 1 */
    grid-row: 186 + (6*1); /* Menempatkan block-a di baris 1 */
    width:253px;
    height:251px;
  }
  .block-o {
    grid-area: o;

    grid-column: 369 + 174 + (5*2); /* Menempatkan block-a di kolom 1 */
    grid-row: 139 + 94 + (6*2); /* Menempatkan block-a di baris 1 */
    width:128px;
    height:64px;
  }
  .block-p {
    grid-area: p;
    grid-column: 1; /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:186px;
    height:331px;
  }
  .block-q {
    grid-area: q;

    grid-column: 1 + 186 + (5*1); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:224px;
    height:143px;
  }
  .block-r {
    grid-area: r;

    grid-column: 1 + 186 + 224 + (5*2); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:132px;
    height:143px;
  }
  .block-s {
    grid-area: s;

    grid-column: 1 + 186 + 224 + 132 + (5*3); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:226px;
    height:143px;
  }
  .block-t {
    grid-area: t;

    grid-column: 1 + 186 + 224 + 132 + 226+ (5*4); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:215px;
    height:204px;
  }
  .block-u {
    grid-area: u;

    grid-column: 1 + 186 + 224 + 132 + 226+ 215+(5*5); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:141px;
    height:204px;
  }
  .block-v {
    grid-area: v;

    grid-column: 1 + 186 + 224 + 132 + 226+ 215 + 141+(5*6); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + (6*2); /* Menempatkan block-a di baris 1 */
    width:146px;
    height:212px;
  }
  .block-w {
    grid-area: w;

    grid-column: 1 + 186 + 224 + 132 + 226+ 215 + 141+146+(5*7); /* Menempatkan block-a di kolom 1 */
    grid-row: 186 + 251+(6*2); /* Menempatkan block-a di baris 1 */
    width:137px;
    height:77px;
  }
  .block-x {
    grid-area: x;

    grid-column: 1 + 186 + 224 + 132 + 226+ 215 + 141+146+137+(5*8); /* Menempatkan block-a di kolom 1 */
    grid-row: 186 + 251+(6*2); /* Menempatkan block-a di baris 1 */
    width:110px;
    height:197px;
  }
  .block-y {
    grid-area: y;

    grid-column: 1 + 186 + (5*1); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 143 + (6*3); /* Menempatkan block-a di baris 1 */
    width:131px;
    height:182px;
  }
  .block-z {
    grid-area: z;

    grid-column: 1 + 186 + 131 + (5*2); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 143 + (6*3); /* Menempatkan block-a di baris 1 */
    width:323px;
    height:182px;
  }

  .block-aa {
    grid-column: 1 + 186 + 131 + 323 + (5*3); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 143 + (6*3); /* Menempatkan block-a di baris 1 */
    width:128px;
    height:182px;
  }

  .block-ab {
    grid-column: 1 + 186 + 131 + 323 + 128 + (5*4); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 204 + (6*3); /* Menempatkan block-a di baris 1 */
    width:215px;
    height:122px;
  }

  .block-ac {
    grid-column: 1 + 186 + 131 + 323 + 128 + 215 + (5*5); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 204 + (6*3); /* Menempatkan block-a di baris 1 */
    width:141px;
    height:122px;
  }

  .block-ad {
    grid-column: 1 + 186 + 131 + 323 + 128 + 215 + 141 + (5*6); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 204 + (6*4); /* Menempatkan block-a di baris 1 */
    width:201px;
    height:114px;
  }

  .block-ae {
    grid-column: 1 + 186 + 131 + 323 + 128 + 215 + 141 + 201 + (5*7); /* Menempatkan block-a di kolom 1 */
    grid-row: 95 + 208 + 204 + (6*4); /* Menempatkan block-a di baris 1 */
    width:82px;
    height:114px;
  }
}

.home-gallery.edit {
  .gallery-block {
    background-color: black;
    cursor: pointer;
  }
  .gallery-block:hover {
    border: 3px solid #d56161;
  }

  .gallery-item:hover {
    .image-overlay {
      opacity: 0;
    }
  }
}

.gallery-item:hover {
  .image-overlay {
    opacity: 1;
  }
}

.gallery-item.empty:hover {
  .image-overlay {
    opacity: 0;
  }
}

.thumbnail {
  position: relative;
}

.image-overlay {
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(14, 47, 71, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;

  svg {
    width: 70px;
    height: 70px;
  }
}

.gallery-item-form {
  margin-top:100px;
  position: absolute;
  width: 90%;
  left: 5%;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  z-index: 1000;

  button {
    font-size: 1.4rem;
    border: none;
  }

  img {
    opacity: 0.9;
    margin: 10px;
    width: 100px;
    height: auto;
    cursor: pointer;
  }

  img:hover {
    opacity: 1;
  }
}

